import { SOPTalkdown, SOPTalkdownType } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import WorkflowFormTalkdownItem from './WorkflowFormTalkdownItem';

interface Props {
  talkdowns: SOPTalkdown[];
  onChange: (val: SOPTalkdown[]) => void;
}

export function WorkflowFormTalkdowns(props: Props) {
  const { onChange, talkdowns } = props;

  const handleOnChange = (index: number) => (updatedVal: SOPTalkdown) => {
    const updatedTalkdowns = [...talkdowns];
    updatedTalkdowns[index] = updatedVal;
    onChange(updatedTalkdowns);
  };

  const onDelete = (index: number) => () => {
    const updatedTalkdowns = [...talkdowns];
    updatedTalkdowns.splice(index, 1);
    onChange(updatedTalkdowns);
  };

  const addTalkdown = () => {
    const updatedTalkdowns = [...talkdowns];
    updatedTalkdowns.push({ type: SOPTalkdownType.STATIC, text: '' });
    onChange(updatedTalkdowns);
  };

  return (
    <div
      className={clsx(
        'flex flex-wrap justify-around gap-2',
        talkdowns.length > 1 ? 'col-span-2' : 'col-span-1'
      )}
    >
      {talkdowns.map((item, i) => (
        <WorkflowFormTalkdownItem
          talkdown={item}
          key={i}
          onChange={handleOnChange(i)}
          onDelete={onDelete(i)}
          isDeleteEnabled={talkdowns.length > 1}
        />
      ))}
      {talkdowns.length === 1 && (
        <div className="self-center">
          <Button variant="outline" onClick={addTalkdown}>
            Add Talkdown
          </Button>
        </div>
      )}
    </div>
  );
}

export default WorkflowFormTalkdowns;
