/* eslint-disable max-lines */
import { useLocations } from '@hakimo-ui/hakimo/data-access';
import { LocationFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  useCanAddLocation,
  useCanUpdateLocation,
  useLocalStorage,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useEffect, useMemo, useState } from 'react';
import AddUpdateLocation from '../add-update-location/AddUpdateLocation';
import { useOnClickRow } from '../hooks';
import { useLocalDispatch, useLocalState } from '../store/StateProvider';
import {
  createUpdateFiltersAction,
  createUpdatePageAction,
} from '../store/action-creators';
import { TableHeader } from './TableHeader';
import FilterPanel from './filter-panel/FilterPanel';
import {
  FilterItem,
  columns,
  getSearchParams,
  getTableData,
  searchFilters,
} from './util';

export function LocationList() {
  const state = useLocalState();
  const dispatch = useLocalDispatch();
  const { filters, page, pageSize } = state;

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });

  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openAddLocation, setOpenAddLocation] = useState(false);

  const [selectedFilter, setSelectedfilter] = useState<FilterItem>(
    searchFilters[0]
  );
  const [searchValue, setSearchValue] = useState('');
  const canUpdateLocation = useCanUpdateLocation();

  const allColumns = useMemo(
    () =>
      canUpdateLocation
        ? columns
        : columns.filter((col) => col.id !== 'actions'),
    [canUpdateLocation]
  );

  const [shownColumns, setShownColumns] = useLocalStorage<string[]>(
    'location-list-shown-columns',
    allColumns.map((col) => col.id)
  );

  const { isLoading, isError, error, data } = useLocations(
    getSearchParams(filters, page, pageSize, searchValue, selectedFilter)
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;
  const onClickRow = useOnClickRow();
  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(
        getTableData(items, onClickRow, allColumns, shownColumns || [])
      );
      setTotal(t);
    }
  }, [data, onClickRow, searchValue, allColumns, shownColumns]);

  const onApplyFilters = (value: LocationFilters) => {
    dispatch(createUpdateFiltersAction(value));
    dispatch(createUpdatePageAction(1));
  };

  const canAddLocation = useCanAddLocation();
  const onChangeFilter = (filterValue: FilterItem) => {
    setSelectedfilter(filterValue);
  };
  const onSearchFilter = (value: string) => {
    if (value !== searchValue) {
      setSearchValue(value);
      dispatch(createUpdatePageAction(1));
    } else {
      setSearchValue(value);
    }
  };

  const onChangeShownColumns = (val: string[]) => setShownColumns(val);
  const isDataValid = data && data.items.length > 0 ? true : false;

  const tableHeader = (
    <TableHeader
      canAddLocation={canAddLocation}
      hasCustomFiltersApplied={filters.name.values.length > 0}
      isLoading={isLoading}
      onClickAddLocation={() => setOpenAddLocation(true)}
      onClickFilter={() => setOpenFilterPanel(true)}
      onSearch={onSearchFilter}
      filterValue={selectedFilter}
      onChangeFilter={onChangeFilter}
      allColumns={allColumns}
      shownColumns={shownColumns || []}
      onChangeShownColumns={onChangeShownColumns}
      filters={filters}
      isDataValid={isDataValid}
    />
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        disabled={isLoading}
        onChangePage={(val) => dispatch(createUpdatePageAction(val))}
      />
    </div>
  );

  return (
    <Page title="Locations">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      {openAddLocation && (
        <AddUpdateLocation
          onClose={() => setOpenAddLocation(false)}
          mode="add"
        />
      )}
      <FilterPanel
        open={openFilterPanel}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClose={() => setOpenFilterPanel(false)}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          header={tableHeader}
          footer={tableFooter}
          loading={isLoading}
        />
      </div>
    </Page>
  );
}

export default withAuthz(withErrorBoundary(LocationList), ['location:view']);
