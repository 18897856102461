import { ScanWsProvider } from '@hakimo-ui/hakimo/providers';
import { WsConnectionState } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { useAuthUtils, useScanWs, useUser } from '@hakimo-ui/hakimo/util';
import { Alert, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { ReactNode, useMemo } from 'react';
import { ScanMonitoring } from './pages/scan-monitoring/ScanMonitoring';

export function ScanRoot() {
  const user = useUser();
  const { getAccessToken } = useAuthUtils();

  const wsUrl = useMemo(() => {
    if (!user.visionTenants || user.visionTenants.length === 0) {
      return;
    }
    const allTenants = user.visionTenants.map((tenant) => tenant.id).join(',');
    const host = window.location.hostname;
    return `wss://event-flow-${host}/events?tenants=${allTenants}`;
  }, [user.visionTenants]);

  return (
    <Page title="Scan" fullWidth>
      {user.visionTenants && user.visionTenants.length > 0 && wsUrl ? (
        <ScanWsProvider url={wsUrl} getAccessToken={getAccessToken}>
          <ScanWrapper>
            <ScanMonitoring visionTenants={user.visionTenants} />
          </ScanWrapper>
        </ScanWsProvider>
      ) : (
        <Alert type="info">
          You do not currently have access to this page. Please contact the
          system administrator to request access permissions. Thank you!
        </Alert>
      )}
    </Page>
  );
}

interface ScanWrapperProps {
  children: ReactNode;
}
function ScanWrapper(props: ScanWrapperProps) {
  const { connectionState } = useScanWs();

  switch (connectionState) {
    case WsConnectionState.PENDING:
      return (
        <div className="z-10 flex h-full w-full items-center justify-center">
          <HakimoSpinner />
        </div>
      );
    case WsConnectionState.CLOSED:
      return (
        <Alert type="error">
          Scan connection terminated. This may be due to a connection error or
          login from another location. Refresh to use Scan in this window.
        </Alert>
      );
    case WsConnectionState.ERROR:
      return (
        <Alert type="error">
          An error occurred while connecting to Scan. Please try again later.
        </Alert>
      );
    case WsConnectionState.CONNECTED:
      return props.children;

    default:
      return null;
  }
}

export default ScanRoot;
