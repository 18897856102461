import { DetectionType } from '@hakimo-ui/hakimo/types';
import { StatusStepper } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  onClose: () => void;
  isQuickResolve?: boolean;
  onSubmitResolveAction: (comment: string) => void;
  isLoading: boolean;
}

export function ResolveAction(props: Props) {
  const { onClose, isQuickResolve, onSubmitResolveAction, isLoading } = props;

  return (
    <div className="divide-onlight-line-3 dark:divide-ondark-line-3 space-y-6 divide-y">
      <span>Resolve the alarm</span>
      <div className="pt-5">
        <StatusStepper
          onClose={onClose}
          onSubmit={onSubmitResolveAction}
          isSubmitInProgress={isLoading}
          initialDetectionType={
            isQuickResolve ? DetectionType.INCORRECT_DETECTION : undefined
          }
        />
      </div>
    </div>
  );
}
