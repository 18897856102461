import {
  useCreateLocationAlarmIncident,
  useLocationById,
} from '@hakimo-ui/hakimo/data-access';
import { UpsertLocationAlarmIncidentDTO } from '@hakimo-ui/hakimo/types';
import { eventTracker, toast, useUser } from '@hakimo-ui/hakimo/util';
import { Button, Textarea } from '@hakimo-ui/shared/ui-base';
import dayjs from 'dayjs';
import { useState } from 'react';

interface Props {
  locationId: string;
  locationAlarmId: string;
  navToEscalation: () => void;
  onCancel: () => void;
  selectedSituationText: string;
}

export function PreEscalate(props: Props) {
  const {
    locationId,
    locationAlarmId,
    navToEscalation,
    onCancel,
    selectedSituationText,
  } = props;
  const [textValue, setTextValue] = useState(
    selectedSituationText ? `${selectedSituationText}:` : ''
  );

  const locationDetails = useLocationById(locationId).data;
  const onSuccess = () => {
    toast(`Alarm is being escalated at ${locationDetails?.name}`, {
      type: 'success',
    });
  };
  const user = useUser();
  const mutation = useCreateLocationAlarmIncident(onSuccess);

  const onTextValueChange = (val: string) => setTextValue(val);

  const onEscalate = () => {
    const payload: UpsertLocationAlarmIncidentDTO = {
      location_id: +locationId,
      location_alarm_id: locationAlarmId,
      tenant_id: locationDetails?.tenant_id,
      title: `Escalation alert: Ongoing incident at ${locationDetails?.name}`,
      created_by: user.id,
      description: textValue,
      incident_start_time_utc: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
    };
    mutation.mutate(payload);
    navToEscalation();
  };

  return (
    <div className="divide-onlight-line-3 dark:divide-ondark-line-3 space-y-6 divide-y">
      <div className="pt-4 font-bold">
        This is being escalated. Please provide a brief update on the current
        situation.
      </div>
      <div className="pt-6">
        <Textarea
          onChange={onTextValueChange}
          value={textValue}
          rows={4}
          placeholder="Even a 10 word insight would do the work. (Optional)"
        />
      </div>
      <div className="space-x-4 pt-6 text-center">
        <Button
          variant="primary"
          onClick={onEscalate}
          onSideEffect={eventTracker('create_location_incident')}
        >
          Proceed
        </Button>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PreEscalate;
