/* eslint-disable max-lines */
import {
  useAIOutputLocationAlarm,
  useLocationMonitoringSchedule,
  useSOP,
  useUpdateLocationAlarm,
} from '@hakimo-ui/hakimo/data-access';
import {
  LocationAlarmCamera,
  LocationAlarmIncidentDTO,
  LocationAlarmStatus,
  SOPWorkflow,
  StatusType,
} from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { ResolveAction } from './ResolveAction';
import { SOPWorkflowRoot } from './SOPWorkflowRoot';
import WorkflowHeader from './WorkflowHeader';
import { EscalateAlarm } from './escalate/EscalateAlarm';
import {
  currentStepAtom,
  DayLocationMonitoringConfig,
  getTodaysMonitoringConfig,
  recommendationsforTenants,
  updateDefaultValues,
  WorkflowItemType,
} from './util';

interface Props {
  locationId: string;
  locationAlarmId: string;
  alarmCameras: LocationAlarmCamera[];
  onAlarmResolve: (status: StatusType) => void;
  locationAlarmStatus: LocationAlarmStatus;
  locationAlarmIncident?: LocationAlarmIncidentDTO;
  locationTenantId?: string;
}

export function SOPWorkflowContainer(props: Props) {
  const {
    locationId,
    locationAlarmId,
    alarmCameras,
    onAlarmResolve,
    locationAlarmIncident,
    locationAlarmStatus,
    locationTenantId,
  } = props;
  const [sopWorkflow, setSOPWorkflow] = useState<SOPWorkflow>();
  const [isQuickResolve, setIsQuickResolve] = useState<boolean | undefined>(
    false
  );
  const [selectedSituationText, setSelectedSituationText] = useState('');
  const [locationMonitoringConfigToday, setLocationMonitoringConfigToday] =
    useState<DayLocationMonitoringConfig>();
  const [currentAtomStep, setCurrentAtomStep] = useAtom(currentStepAtom);

  const { data: monitoringConfigData } = useLocationMonitoringSchedule(
    +locationId
  );

  const { data: aiOutput, isLoading: isAILoading } =
    useAIOutputLocationAlarm(locationAlarmId);
  useEffect(() => {
    if (monitoringConfigData) {
      const motionDetectionWindow = monitoringConfigData.motionDetectionWindow;

      setLocationMonitoringConfigToday(
        getTodaysMonitoringConfig(motionDetectionWindow)
      );
    }
  }, [monitoringConfigData]);

  const resolveMutation = useUpdateLocationAlarm(locationAlarmId, () => {
    toast('Alarm status updated', { type: 'success' });
    onAlarmResolve(StatusType.RESOLVED);
  });

  const onSubmitResolveAction = (comment: string) => {
    resolveMutation.mutate({ status: StatusType.RESOLVED, comment });
  };

  const { isLoading, isRefetching, isError, error } = useSOP(
    {
      locationId,
    },
    async ({ sop_workflow: workflow }) => {
      if (workflow) {
        const updatedWorkflow = updateDefaultValues(workflow);
        setSOPWorkflow(updatedWorkflow);
      }
    }
  );
  const isIncidentTriggeringAlarm =
    locationAlarmIncident &&
    locationAlarmIncident.initiatingLocationAlarmId === locationAlarmId;
  const [currentWorkflowItemType, setCurrentWorkflowItemType] = useState<
    WorkflowItemType | undefined
  >(
    isIncidentTriggeringAlarm
      ? WorkflowItemType.ESCALATE
      : WorkflowItemType.STEPS
  );

  useEffect(() => {
    setCurrentAtomStep(0);
  }, [locationAlarmId, setCurrentAtomStep]);

  useEffect(() => {
    if (locationAlarmStatus === 'Resolved') {
      setCurrentWorkflowItemType(undefined);
    }
  }, [locationAlarmStatus]);

  useEffect(() => {
    if (
      currentAtomStep === 0 &&
      currentWorkflowItemType === WorkflowItemType.STEPS
    ) {
      setSelectedSituationText('');
    }
  }, [currentAtomStep, currentWorkflowItemType]);

  const navigateTo = (val: WorkflowItemType) => () => {
    setCurrentWorkflowItemType(val);
    if (val === WorkflowItemType.STEPS && currentAtomStep === 0) {
      setSelectedSituationText('');
    }
  };

  const navigateToResolve = (isQuickResolveVal?: boolean) => {
    navigateTo(WorkflowItemType.RESOLVE)();
    setIsQuickResolve(isQuickResolveVal === true); // explicitly checking with true because in other cases click event data is coming
  };

  const onStepChange = (step: number) => {
    setCurrentAtomStep(step);
    if (step === 0) {
      setSelectedSituationText('');
    }
  };

  const onUpdateSituationsText = (actiontext: string) =>
    setSelectedSituationText(actiontext);

  const isTenantAllowedForRecommendations = () => {
    return locationTenantId
      ? recommendationsforTenants.includes(locationTenantId)
      : false;
  };

  return (
    <div className="relative min-h-[40rem] p-2">
      {(isError || sopWorkflow === undefined) && (
        <div className="mx-auto max-w-screen-2xl p-6">
          {error?.message && <Alert type="error">{error.message ?? ''}</Alert>}
          {!(isLoading || isRefetching) && (
            <span>Please ask your admin to update the SOP.</span>
          )}
        </div>
      )}
      {(isLoading || isRefetching) && (
        <div className="bg-onlight-bg-1 dark:bg-ondark-bg-2 absolute inset-0 z-10 flex justify-center p-2 opacity-70">
          <HakimoSpinner />
        </div>
      )}

      {sopWorkflow && (
        <div
          className={clsx(
            currentWorkflowItemType === WorkflowItemType.STEPS &&
              'flex min-h-[25rem] flex-col items-center justify-between gap-3'
          )}
        >
          {locationAlarmStatus === 'Resolved' && (
            <div>This location alarm is already resolved</div>
          )}

          {isTenantAllowedForRecommendations() &&
            aiOutput &&
            !isAILoading &&
            locationAlarmStatus !== 'Resolved' &&
            aiOutput?.recommendations && (
              <div className="mb-2 w-full">
                <div className="text-primary-800 dark:text-primary-400 mb-1 flex items-center justify-between pr-8 font-sans font-semibold">
                  Recommendation
                  <span className="inline-flex items-center rounded-md px-4 py-2 text-sm font-bold text-gray-600 dark:text-gray-300">
                    {aiOutput.recommendations}
                  </span>
                </div>
                <div className="relative mt-2 pb-2">
                  <div className="from-ondark-text-2 pointer-events-none absolute inset-y-0 right-0 w-[2px] bg-gradient-to-b to-transparent"></div>
                  <div className="from-ondark-text-2 pointer-events-none absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r to-transparent"></div>
                  <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 flex max-h-16 flex-wrap gap-2 overflow-y-auto py-1 pr-2">
                    {aiOutput.summary && (
                      <div className="mt-1 w-full text-sm text-gray-600 dark:text-gray-400">
                        {aiOutput.summary}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          <div className="divide-onlight-line-3 dark:divide-ondark-line-3 relative w-full space-y-4 divide-y">
            {currentWorkflowItemType !== WorkflowItemType.RESOLVE &&
              locationAlarmStatus !== 'Resolved' &&
              sopWorkflow && (
                <div>
                  <div className="flex items-start">
                    <WorkflowHeader
                      totalSteps={
                        currentWorkflowItemType === WorkflowItemType.STEPS
                          ? sopWorkflow.talkdowns
                            ? sopWorkflow.talkdowns.length + 1
                            : 1
                          : 1
                      }
                      currentStep={
                        currentWorkflowItemType === WorkflowItemType.STEPS
                          ? currentAtomStep
                          : 0
                      }
                      onStepChange={
                        currentWorkflowItemType === WorkflowItemType.STEPS
                          ? onStepChange
                          : undefined
                      }
                      locationMonitoringConfigToday={
                        locationMonitoringConfigToday
                      }
                      isZeroToleranceSite={sopWorkflow.isZeroTolerance}
                      exceptions={sopWorkflow.exceptions}
                      notes={sopWorkflow.notes}
                      showExceptionsAndNotes={
                        currentWorkflowItemType === WorkflowItemType.ESCALATE
                      }
                    />
                  </div>
                  {currentWorkflowItemType === WorkflowItemType.STEPS && (
                    <Button
                      variant="error"
                      onClick={navigateTo(WorkflowItemType.ESCALATE)}
                      className="absolute -top-1 right-8"
                    >
                      Escalate
                    </Button>
                  )}
                </div>
              )}

            {currentWorkflowItemType === WorkflowItemType.STEPS &&
              alarmCameras.length > 0 && (
                <SOPWorkflowRoot
                  navigateToEscalate={navigateTo(WorkflowItemType.ESCALATE)}
                  navigateToResolve={navigateToResolve}
                  sopWorkflow={sopWorkflow}
                  alarmCameras={alarmCameras}
                  locationAlarmId={locationAlarmId}
                  onQuickResolve={onSubmitResolveAction}
                  isResolveSubmitting={resolveMutation.isLoading}
                  selectedSituationsText={selectedSituationText}
                  onUpdateSituationsText={onUpdateSituationsText}
                />
              )}
            {currentWorkflowItemType === WorkflowItemType.RESOLVE && (
              <ResolveAction
                onClose={navigateTo(WorkflowItemType.STEPS)}
                isQuickResolve={isQuickResolve}
                onSubmitResolveAction={onSubmitResolveAction}
                isLoading={resolveMutation.isLoading}
              />
            )}
            {currentWorkflowItemType === WorkflowItemType.ESCALATE && (
              <EscalateAlarm
                escalationPoints={sopWorkflow.escalationPoints}
                escalationProtocol={sopWorkflow.escalationProtocol || []}
                isEscalated={!!locationAlarmIncident}
                locationAlarmId={locationAlarmId}
                locationId={locationId}
                siteAddress={sopWorkflow.siteAddress}
                siteGoogleMapLocation={sopWorkflow.siteGoogleMapLocation}
                locationTenantId={locationTenantId}
                onCancel={navigateTo(WorkflowItemType.STEPS)}
                selectedSituationText={selectedSituationText}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
