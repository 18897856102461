import { SiteSchedule } from '@hakimo-ui/hakimo/types';
import { useEffect, useState } from 'react';
import { DayRange, convertToTimeFromSeconds, getWeekRanges } from './util';

interface Props {
  schedules: SiteSchedule[];
  timezone?: string;
}

export function MonitoringWidget(props: Props) {
  const { schedules, timezone } = props;
  const [fullWeekRanges, setFullWeekRanges] = useState<DayRange[]>([]);

  useEffect(() => {
    const weekRanges = getWeekRanges(schedules);
    setFullWeekRanges(weekRanges);
  }, [schedules]);

  const barWidth = 400;
  const fullDaySeconds = 24 * 60 * 60;

  const getSectionWidth = (startTime: number, endTime: number) => {
    const sectionTimeSpanInSec = endTime - startTime;
    return Math.floor((sectionTimeSpanInSec * barWidth) / fullDaySeconds);
  };

  const getRelativePosition = (timeInSeconds: number) => {
    return (timeInSeconds * barWidth) / fullDaySeconds;
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="text-center underline">
        Monitoring overview ({timezone})
      </div>
      {fullWeekRanges.map((day, i) => (
        <div key={`${i}-${day.name}`} className="flex items-center gap-10">
          <span className="text-sm">{day.symbol}</span>
          <div
            style={{ width: `${barWidth}px` }}
            className="dark:border-ondark-line-2 relative h-5 border"
          >
            {day.ranges.map((range, j) => (
              <div key={j}>
                <div
                  style={{
                    width: `${getSectionWidth(
                      range.startTime,
                      range.endTime
                    )}px`,
                    left: getRelativePosition(range.startTime),
                  }}
                  className="bg-primary-300 dark:bg-primary-700 absolute top-[1px] z-10 h-4"
                ></div>
                <span
                  style={{ left: getRelativePosition(range.startTime) - 8 }}
                  className="absolute -top-4 text-xs"
                >
                  {convertToTimeFromSeconds(range.startTime)}
                </span>
                <span
                  style={{ left: getRelativePosition(range.endTime) - 8 }}
                  className="absolute -bottom-4 text-xs"
                >
                  {convertToTimeFromSeconds(range.endTime)}
                </span>
                <span className="absolute right-0 top-1 z-0 text-[8px]">
                  24:00
                </span>
                <span className="absolute left-0 top-1 z-0 text-[8px]">
                  00:00
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="dark:border-dark-border-surface flex items-center justify-center gap-4 border-t p-2">
        <div className="bg-primary-300 dark:bg-primary-700 h-5 w-5"></div>
        <span className="text-xs">Monitored Hours</span>
      </div>
    </div>
  );
}

export default MonitoringWidget;
