/* eslint-disable max-lines */
import { useSendAudio } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarmCamera, SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { toast, trackEvent } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { WorkflowActions } from './WorkflowActions';
import { WorkflowBody } from './WorkflowBody';
import {
  currentStepAtom,
  getLatestCameraWithTalkdown,
  getSOPSteps,
} from './util';

interface Props {
  navigateToResolve: (isQuickResolve?: boolean) => void;
  navigateToEscalate: () => void;
  sopWorkflow: SOPWorkflow;
  locationAlarmId: string;
  alarmCameras: LocationAlarmCamera[];
  onQuickResolve: (comment: string) => void;
  isResolveSubmitting: boolean;
  selectedSituationsText: string;
  onUpdateSituationsText: (actiontext: string) => void;
}

export function SOPWorkflowRoot(props: Props) {
  const {
    navigateToEscalate,
    navigateToResolve,
    sopWorkflow,
    alarmCameras,
    locationAlarmId,
    onQuickResolve,
    isResolveSubmitting,
    selectedSituationsText,
    onUpdateSituationsText,
  } = props;

  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [talkdownInitiated, setTalkdownInitiated] = useState(false);

  const latestCameraWithSpeaker = useMemo(() => {
    return getLatestCameraWithTalkdown(alarmCameras);
  }, [alarmCameras]);

  const navigateToNextStep = (actionText?: string) => {
    setCurrentStep((prev) => prev + 1);
    setTalkdownInitiated(false);
    actionText && onUpdateSituationsText(actionText);
  };

  const logTalkdown = useCallback(
    (cameraId: string) => {
      trackEvent('submit_camera_details_audio_talkdown', {
        camera_id: cameraId,
        locationAlarmId,
      });
      setTalkdownInitiated(true);
    },
    [locationAlarmId]
  );

  const mutation = useSendAudio(
    latestCameraWithSpeaker ? latestCameraWithSpeaker.id : null,
    undefined,
    locationAlarmId,
    () => {
      logTalkdown(latestCameraWithSpeaker ? latestCameraWithSpeaker.id : '');
      toast('Audio sent to camera successfully');
    }
  );

  const performTalkdown = useCallback(
    (file?: File) => {
      const formData = new FormData();
      formData.append('audio', file || '');
      mutation.mutate(formData);
    },
    [latestCameraWithSpeaker, logTalkdown, mutation]
  );

  const logAndEscalate = (actionText?: string) => {
    const updatedSituationText = actionText ?? selectedSituationsText;
    onUpdateSituationsText(updatedSituationText);
    trackEvent('sop_workflow_escalate_location_alarm_step_count', {
      locationAlarmId,
      escalated_after_step_count: currentStep,
    });
    navigateToEscalate();
  };

  const items = getSOPSteps(
    navigateToNextStep,
    navigateToResolve,
    sopWorkflow,
    logAndEscalate,
    latestCameraWithSpeaker !== null
  );

  const currentItem = items[currentStep];

  const showQuestionAndAction = talkdownInitiated;

  const onQuickResolveAction = (action: string) => () => {
    if (action.toLowerCase() === 'others') {
      navigateToResolve();
      return;
    }
    onQuickResolve(action);
  };

  return (
    <>
      <div className="relative w-full space-y-4">
        <WorkflowBody
          sopItem={currentItem}
          cameraId={latestCameraWithSpeaker?.id || ''}
          cameraName={latestCameraWithSpeaker?.name || ''}
          logTalkdown={logTalkdown}
          performTalkdown={performTalkdown}
          isTalkdownInProcess={mutation.isLoading}
          locationAlarmId={locationAlarmId}
        />

        {showQuestionAndAction && (
          <>
            <div className="pt-4 font-bold">{currentItem.title}</div>
            <WorkflowActions
              sopItem={currentItem}
              locationAlarmId={locationAlarmId}
              stepNumber={currentStep}
            />
          </>
        )}
      </div>
      <div className="w-full pt-2">
        {sopWorkflow?.quickResolveActions &&
          sopWorkflow.quickResolveActions.length > 0 &&
          currentStep >= 0 && (
            <div className="space-y-4">
              <div className="text-primary-800 dark:text-primary-400 mb-1 font-sans text-sm font-semibold">
                Quick Resolve
              </div>

              <div className="mt-auto flex flex-wrap items-center justify-evenly gap-2">
                {sopWorkflow.quickResolveActions.map((action, i) => (
                  <Button
                    key={i}
                    onClick={onQuickResolveAction(action)}
                    disabled={isResolveSubmitting}
                    className="p-1 px-2"
                  >
                    {action}
                  </Button>
                ))}
              </div>
            </div>
          )}
      </div>
    </>
  );
}
