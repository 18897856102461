import { useState } from 'react';
import dayjs from 'dayjs';
import { Alert, Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';

interface CustomDateRangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (startDate: number, endDate: number) => void;
}

export function CustomDateRangeModal(props: CustomDateRangeModalProps) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const currentDateTime = dayjs().format('YYYY-MM-DDTHH:mm');

  const handleApply = () => {
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();

    if (startTimestamp && endTimestamp && startTimestamp <= endTimestamp) {
      props.onApply(startTimestamp, endTimestamp);
      setError('');
    } else {
      setError('End Date should not be less than Start Date');
    }
  };
  const isApplyDisabled = !startDate || !endDate;

  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      title="Select Custom Range"
      footer={
        <>
          <Button variant="outline" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleApply}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
        </>
      }
    >
      <div className="mx-auto min-w-[40vw] rounded-lg p-6 shadow-lg">
        {error && (
          <Alert type="warning" className="mb-4">
            {error}
          </Alert>
        )}
        <div className="mt-4 space-y-4">
          <div>
            <InputField
              type="datetime-local"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Select start date"
              max={currentDateTime}
            />
          </div>
          <div>
            <InputField
              type="datetime-local"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="Select end date"
              max={currentDateTime}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
