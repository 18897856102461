import { useSingleLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarmStatus } from '@hakimo-ui/hakimo/types';
import { NotFound, PageLoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { withAuthz, withErrorBoundary } from '@hakimo-ui/hakimo/util';
import { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
  unstable_usePrompt as usePrompt,
} from 'react-router-dom';
import { AlarmOverview } from '../location-alarms/alarm-overview/AlarmOverview';
import QuickActionsPanel from './quick-actions-panel/QuickActionsPanel';

interface Props {
  locationAlarmId: string;
}

export function LocationAlarmDetailsData(props: Props) {
  const { locationAlarmId } = props;
  const navigate = useNavigate();
  const [canExit, setCanExit] = useState<boolean>(false);
  const queryResult = useSingleLocationAlarm(locationAlarmId, 2000);

  const onStatusChange = (status: LocationAlarmStatus) => {
    if (status === 'Resolved') {
      setCanExit(true);
      // Wait for a tick to allow the canExit state to be set
      setTimeout(() => navigate('/monitoring'));
    }
  };

  useEffect(() => {
    if (queryResult.data?.status === 'Resolved') {
      setCanExit(true);
    }
  }, [queryResult.data?.status]);

  usePrompt({
    when: !canExit,
    message: 'If you leave now, this alarm will be dropped!',
  });

  return queryResult.data ? (
    <Page title="Location Alarm">
      <div className="flex">
        <div className="flex-1">
          <AlarmOverview
            alarm={queryResult.data}
            onStatusChange={onStatusChange}
            showContacts={false}
            showSOP={false}
            showResolveAction={false}
          />
        </div>
        <div className="flex-0">
          <QuickActionsPanel
            locationAlarm={queryResult.data}
            onAlarmResolve={onStatusChange}
          />
        </div>
      </div>
    </Page>
  ) : (
    <PageLoadingIndicator />
  );
}

type Params = {
  locationAlarmId: string;
};

function LocationAlarmMonitoringDetails() {
  const { locationAlarmId } = useParams<Params>();

  if (locationAlarmId === undefined) {
    return <NotFound />;
  }

  return <LocationAlarmDetailsData locationAlarmId={locationAlarmId} />;
}

export default withAuthz(withErrorBoundary(LocationAlarmMonitoringDetails), [
  'location_alarm/detail:view',
]);
