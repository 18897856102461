import { useLocationAlarmUpdates } from '@hakimo-ui/hakimo/data-access';
import { QueryResult, Timeline } from '@hakimo-ui/hakimo/ui-elements';
import { useIsSupportRole, useExportCsv } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { getLocationAlarmUpdateEvents } from './util';
import { getFormattedExportData } from './extraDetailsUtil';
import { useState } from 'react';

const REFETCH_INTERVAL = 60000;

export interface Props {
  locationAlarmId: string;
  locationName: string;
}

export function AlarmUpdates(props: Props) {
  const { locationAlarmId, locationName } = props;
  const query = useLocationAlarmUpdates(locationAlarmId, REFETCH_INTERVAL);
  const isSupportRole = useIsSupportRole();
  const { exportCsv } = useExportCsv();
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = (events: any[]) => {
    setIsExporting(true);
    try {
      if (!events.length || !query.data?.items) {
        throw new Error('No data to export');
      }

      const formattedData = getFormattedExportData(events, query.data);
      if (!Array.isArray(formattedData) || formattedData.length === 0) {
        throw new Error('Formatted data is invalid or empty');
      }
      exportCsv({
        formattedData,
        fileName: `alarm-updates-${locationAlarmId}-${locationName}.csv`,
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error(
        `Export Failed: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="space-y-1">
      <div className="flex items-center justify-between">
        <h2 className="flex h-9 items-center">Alarm Updates</h2>
        {isSupportRole && (
          <Button
            variant="icon"
            onClick={() => {
              if (!query.data) return;
              const events = getLocationAlarmUpdateEvents(
                query.data,
                isSupportRole
              );
              handleExport(events);
            }}
            loading={isExporting}
            title="Export Alarm Updates"
            disabled={!query.data}
          >
            <ArrowDownTrayIcon className="w-5" />
          </Button>
        )}
      </div>
      <QueryResult queryResult={query} loadingText="">
        {(data) => {
          const events = getLocationAlarmUpdateEvents(data, isSupportRole);
          return (
            <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 max-h-[30rem] overflow-auto">
              {events.length > 0 ? (
                <Timeline events={events} />
              ) : (
                <div className="p-6">
                  <Alert>No updates</Alert>
                </div>
              )}
            </div>
          );
        }}
      </QueryResult>
    </div>
  );
}

export default AlarmUpdates;
