import { Situation, SituationColor } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';
import CreateUpdateSituationModal from './CreateUpdateSituationModal';

interface Props {
  items: Situation[];
  onChange?: (val: Situation[]) => void;
  isEditable?: boolean;
}

export function WorkflowFormSituation(props: Props) {
  const { items, onChange, isEditable = true } = props;

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateItem, setUpdateItem] = useState<Situation>();
  const [isNewItem, setIsNewItem] = useState(false);

  const onAddItem = () => {
    setIsNewItem(true);
    setUpdateItem({ label: '', color: SituationColor.BLUE });
    setShowUpdateModal(true);
  };

  const onEditItem = (i: number) => () => {
    setShowUpdateModal(true);
    setUpdateItem(items[i]);
  };

  const onDeleteItem = (i: number) => () => {
    const updatedItems = [...items];
    updatedItems.splice(i, 1);
    onChange?.(updatedItems);
  };

  const onCloseModal = () => {
    setIsNewItem(false);
    setShowUpdateModal(false);
    setUpdateItem(undefined);
  };

  const handleUpdateFromModal = (val: Situation) => {
    if (!updateItem) {
      return;
    }
    const updatedItems = [...items];
    if (isNewItem) {
      updatedItems.push(val);
    } else {
      updatedItems[items.indexOf(updateItem)] = val;
    }
    onChange?.(updatedItems);
    onCloseModal();
  };

  return (
    <div className="col-span-2 space-y-2">
      <div className="flex flex-wrap gap-4">
        {items.map((item, i) => (
          <div
            className={clsx(
              'dark:bg-ondark-bg-2 bg-onlight-bg-2 border-onlight-line-2 dark:border-ondark-line-2 flex cursor-pointer items-center gap-2 rounded-md border p-1 px-2 text-sm',
              item.color === SituationColor.RED &&
                'text-rose-600 dark:text-rose-400',
              item.color === SituationColor.GREEN &&
                'text-teal-600 dark:text-teal-400',
              item.color === SituationColor.BLUE &&
                'text-primary-500 dark:text-primary-400'
            )}
            title={`${item.label}`}
            key={i}
          >
            <span>{item.label}</span>
            {isEditable && (
              <div className="flex items-center">
                <Button variant="icon" onClick={onEditItem(i)}>
                  <PencilIcon className="h-4 w-4" />
                </Button>
                <Button variant="icon" onClick={onDeleteItem(i)}>
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      {isEditable && (
        <div>
          <Button onClick={onAddItem}>Add item</Button>
        </div>
      )}
      {showUpdateModal && updateItem && (
        <CreateUpdateSituationModal
          onClose={onCloseModal}
          situation={updateItem}
          onSubmit={handleUpdateFromModal}
          isNew={isNewItem}
        />
      )}
    </div>
  );
}

export default WorkflowFormSituation;
