import { DoorFilters } from '@hakimo-ui/hakimo/types';
import {
  eventTracker,
  useCanAddDoorsToLocation,
  useCanUpdateDoorsToDoorGroup,
  useExportCsv,
  useIsSupportRole,
} from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { getFormattedExportData, isNotDefaultFilter } from './utils';
import { useDoors } from '@hakimo-ui/hakimo/data-access';
import { getSearchParams } from '../util';
import { useState } from 'react';

interface Props {
  selectedDoors: string[];
  filters: DoorFilters;
  isLoading: boolean;
  onClickFilter: () => void;
  onClickAddToLocation: () => void;
  onClickAddToDoorGroup: () => void;
  onClickRemoveFromDoorGroup: () => void;
  isDataValid: boolean;
}

export function DoorListHeader(props: Props) {
  const {
    selectedDoors,
    filters,
    isLoading,
    onClickFilter,
    onClickAddToLocation,
    onClickAddToDoorGroup,
    onClickRemoveFromDoorGroup,
    isDataValid,
  } = props;

  const [isExporting, setIsExporting] = useState(false);
  const exportSearchParams = getSearchParams(filters);
  const { refetch: fetchExportData } = useDoors(exportSearchParams, false);

  const { exportCsv } = useExportCsv();
  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { data } = await fetchExportData();
      if (!data?.items) {
        throw new Error('Data Fetching Failed');
      }

      const formattedData = getFormattedExportData(data.items);
      if (!Array.isArray(formattedData) || formattedData.length === 0) {
        throw new Error('Formatted data is invalid or empty');
      }
      exportCsv({
        formattedData,
        fileName: 'hakimo-doors-export.csv',
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error(
        `Export Failed: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    } finally {
      setIsExporting(false);
    }
  };

  const canAddDoorsToLocation = useCanAddDoorsToLocation();
  const canUpdateDoorsToDoorGroup = useCanUpdateDoorsToDoorGroup();
  const isSupportRole = useIsSupportRole();
  const DisableExport = !isDataValid || !isSupportRole;

  return (
    <div className="dark:bg-dark-bg border-b bg-white p-4 dark:border-0">
      <div className="flex h-10 items-center">
        <div className="flex flex-1">
          <div className="ml-0">
            {canAddDoorsToLocation && selectedDoors.length > 0 && (
              <Button
                variant="primary"
                onSideEffect={eventTracker('open_add_doors_to_location_modal')}
                onClick={onClickAddToLocation}
              >
                Add {selectedDoors.length} door
                {selectedDoors.length === 1 ? '' : 's'} to location
                <span className="sr-only">Add to Location</span>
              </Button>
            )}
          </div>
          <div className="ml-2">
            {canUpdateDoorsToDoorGroup && selectedDoors.length > 0 && (
              <Button
                variant="primary"
                onSideEffect={eventTracker(
                  'open_add_doors_to_door_group_modal'
                )}
                onClick={onClickAddToDoorGroup}
              >
                Add {selectedDoors.length} door
                {selectedDoors.length === 1 ? '' : 's'} to door group
                <span className="sr-only">Add to Door Group</span>
              </Button>
            )}
          </div>
          <div className="ml-2">
            {canUpdateDoorsToDoorGroup && selectedDoors.length > 0 && (
              <Button
                variant="error"
                onSideEffect={eventTracker('remove_door_from_door_group')}
                onClick={onClickRemoveFromDoorGroup}
              >
                Remove {selectedDoors.length} door
                {selectedDoors.length === 1 ? '' : 's'} from door group
                <span className="sr-only">Remove from Door Group</span>
              </Button>
            )}
          </div>
        </div>
        <span className="mr-2">
          <Button
            variant="icon"
            onClick={handleExport}
            loading={isExporting}
            title="Download Data"
            disabled={DisableExport}
          >
            <ArrowDownTrayIcon className="w-5" />
          </Button>
        </span>
        <Button
          variant="icon"
          badge={isNotDefaultFilter(filters)}
          onClick={onClickFilter}
          disabled={isLoading}
          onSideEffect={eventTracker('open_door_filters_panel')}
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default DoorListHeader;
