import { DetectionType } from '@hakimo-ui/hakimo/types';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import {
  Button,
  InputField,
  Label,
  Selectable,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import SelectMenu from '../select-menu/SelectMenu';
import { getStatusCommentOptions } from './util';

interface Props {
  detectionType: DetectionType;
  onSubmit: (comment: string) => void;
  isSubmitInProgress?: boolean;
}

export function CommentDropdown(props: Props) {
  const { detectionType, onSubmit, isSubmitInProgress } = props;
  const [comment, setComment] = useState<Selectable>({
    id: 'blank',
    name: '',
  });
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const onSelectComment = (item: Selectable) => {
    if (item.name === 'Other') {
      setShowTextField(true);
      setComment({ id: 'blank', name: '' });
    } else {
      setComment(item);
    }
  };

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment({ id: e.target.value, name: e.target.value });
  };

  return (
    <div className="flex flex-wrap items-center gap-6">
      <Label small type="info">
        {detectionType.toUpperCase()}
      </Label>
      <div className="flex flex-wrap items-center gap-4">
        <span>Comment:</span>
        <div className="flex gap-4">
          <div className="w-56 flex-1">
            {showTextField ? (
              <InputField
                label=""
                onChange={onTextFieldChange}
                type="text"
                value={comment?.name || ''}
                placeholder="Please specify"
                autoFocus
              />
            ) : (
              <SelectMenu
                value={comment}
                items={getStatusCommentOptions(detectionType)}
                displayValue={(item) => item?.name ?? ''}
                onChange={onSelectComment}
                id={(item) => item?.id || ''}
              />
            )}
          </div>
        </div>
      </div>
      <Button
        title="Confirm"
        variant="primary"
        loading={isSubmitInProgress}
        disabled={comment.id === 'blank'}
        onClick={() => onSubmit(comment?.name ?? '')}
        onSideEffect={eventTracker('add_status_update_comment')}
      >
        Confirm
      </Button>
    </div>
  );
}

export default CommentDropdown;
