import { Button, InputField } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  items: string[];
  onChange: (val: string[]) => void;
}

export function WorkflowFormListItem(props: Props) {
  const { items, onChange } = props;

  const onInputChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const updatedItems = [...items];
      updatedItems[index] = val;
      onChange(updatedItems);
    };

  const onDeleteItem = (index: number) => () => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const onAddItem = () => {
    const updatedItems = [...items];
    updatedItems.push('');
    onChange(updatedItems);
  };

  return (
    <div className="col-span-2 space-y-4">
      {items.map((item, i) => (
        <div key={i} className="flex items-center gap-4">
          <InputField value={item} type="text" onChange={onInputChange(i)} />
          <Button variant="icon" onClick={onDeleteItem(i)}>
            <TrashIcon className="h-5 w-5" />
          </Button>
        </div>
      ))}
      <div>
        <Button onClick={onAddItem}>Add item</Button>
      </div>
    </div>
  );
}
