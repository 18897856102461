import { LocationMonitoringConfiguration } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useLocationMonitoringSchedule(locationId: number) {
  const url = `/v2/orm/locations/${locationId}/monitoring/window`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationMonitoringConfiguration>(request, {
    queryKey: ['location_monitoring_window', { locationId }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useLocationMonitoringSchedule;
