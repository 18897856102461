/* eslint-disable max-lines */
import { MotionDetectionWindow, SiteSchedule } from '@hakimo-ui/hakimo/types';
import { WEEKDAYS, getWeekdays } from '@hakimo-ui/hakimo/util';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

export const getUpdatedSchedules = (
  scheduleIndex: number,
  newSchedule: SiteSchedule,
  allSchedules: SiteSchedule[]
) => {
  let finalAllSchedules = [...allSchedules];
  const oldSchedule = allSchedules[scheduleIndex];
  const changedDayIndex = newSchedule.enabledDays.findIndex(
    (day, i) => day !== oldSchedule.enabledDays[i]
  );
  //case where only start time and end time are updated
  if (changedDayIndex === -1) {
    finalAllSchedules[scheduleIndex] = newSchedule;
    return finalAllSchedules;
  }
  const changedDayValue = newSchedule.enabledDays[changedDayIndex];
  if (changedDayValue) {
    finalAllSchedules = finalAllSchedules.map((schedule, i) => {
      if (i !== scheduleIndex) {
        schedule.enabledDays[changedDayIndex] = false;
      }
      return schedule;
    });
  } else {
    finalAllSchedules[0].enabledDays[changedDayIndex] = true;
  }
  finalAllSchedules[scheduleIndex] = newSchedule;
  return finalAllSchedules;
};

export const getUpdatedSchedulesOnDelete = (
  deleteIndex: number,
  allSchedules: SiteSchedule[]
) => {
  const updatedSchedules = [...allSchedules];
  const deletedSchedule = updatedSchedules.splice(deleteIndex, 1)[0];
  allSchedules[0].enabledDays = allSchedules[0].enabledDays.map((day, j) => {
    if (deletedSchedule.enabledDays[j]) {
      day = true;
    }
    return day;
  });
  return updatedSchedules;
};

export const convertToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 60 * 60 + minutes * 60 + (seconds ?? 0);
};

export const convertToTimeFromSeconds = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const validateAllSchedules = (schedules: SiteSchedule[]) => {
  let isValid = true;
  let message = '';

  for (let i = 0; i < schedules.length; i++) {
    const currentSchedule = schedules[i];
    for (let j = 0; j < currentSchedule.ranges.length; j++) {
      const currentRange = currentSchedule.ranges[j];
      const startTime = currentRange.startTime;
      const endTime = currentRange.endTime;
      if (startTime === '' || endTime === '') {
        isValid = false;
        message =
          'Please provide a start time and an end time for every schedule.';
        break;
      }
      const isNoDaySelected = currentSchedule.enabledDays.every(
        (day) => day === false
      );
      if (isNoDaySelected) {
        isValid = false;
        message = 'Ensure at least one day is chosen for each schedule.';
      }
    }

    if (currentSchedule.ranges.length > 1) {
      const sortedRanges = [...currentSchedule.ranges].sort(
        (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
      );
      let prevEnd = undefined;
      for (const range of sortedRanges) {
        const startTime = convertToSeconds(range.startTime);
        const endTime = convertToSeconds(range.endTime);

        if (prevEnd && prevEnd > startTime) {
          isValid = false;
          message =
            'Multiple range overlap. End time of first range should not exceed next range start time';
        }
        prevEnd = endTime;
      }
    }
  }

  return { isValid, message };
};

export const getDefaultSchedule = (): SiteSchedule => ({
  ranges: [{ startTime: '', endTime: '' }],
  enabledDays: new Array(7).fill(true),
});

export function getSchedulesFromPayload(
  motionDetectionWindow: MotionDetectionWindow
): SiteSchedule[] {
  return motionDetectionWindow.dayGroupRanges.map((dayGroup) => ({
    ranges: dayGroup.ranges.map((range) => ({
      startTime: range.startAtTz,
      endTime: range.endAtTz,
      order: range.order ?? 0,
    })),
    enabledDays: dayGroup.daysOfWeekTz.reduce((acc, day) => {
      const dayIndex = WEEKDAYS.indexOf(day.toUpperCase());
      acc[dayIndex] = true;
      return acc;
    }, new Array(7).fill(false)),
  }));
}

const getTimeWithSeconds = (time: string) => {
  const timeParts = time.split(':');
  if (timeParts.length === 2) {
    timeParts.push('00');
    return timeParts.join(':');
  }
  return timeParts.join(':');
};

export function getPayloadFromSchedules(
  schedules: SiteSchedule[],
  timezone: string
): MotionDetectionWindow {
  return {
    timezone,
    dayGroupRanges: schedules.map((schedule) => ({
      ranges: schedule.ranges.map((range) => ({
        startAtTz: getTimeWithSeconds(range.startTime),
        endAtTz: getTimeWithSeconds(range.endTime),
        order: range.order,
      })),
      daysOfWeekTz: schedule.enabledDays.reduce((acc, dayEnabled, index) => {
        if (dayEnabled) {
          acc.push(WEEKDAYS[index]);
        }
        return acc;
      }, [] as string[]),
    })),
  };
}

const rotateArrayValues = (arrVal: boolean[], direction: number) => {
  if (direction === 1) {
    // Rotate right
    arrVal.unshift(!!arrVal.pop());
  } else if (direction === -1) {
    // Rotate left
    arrVal.push(!!arrVal.shift());
  }
  return arrVal;
};

export function getUpdatedScheduleOnTimezoneChange(
  schedules: SiteSchedule[],
  newTimezone: string,
  prevTimezone: string
) {
  const updatedSchedules = schedules.map((schedule) => {
    let isDayOfWeekChanged = false;
    let dayChangeDiff = 0;
    schedule.ranges = schedule.ranges.map((range) => {
      const { startTime, endTime, order } = range;
      const isPrevTzUTC = prevTimezone.toLowerCase() === 'utc';

      const [startHour, startMinute] = startTime.split(':');
      let prevTzStartDate = isPrevTzUTC
        ? dayjs.utc()
        : dayjs().tz(prevTimezone);
      prevTzStartDate = prevTzStartDate
        .set('hour', +startHour)
        .set('minute', +startMinute);

      const [endHour, endMinute] = endTime.split(':');
      let prevTzEndDate = isPrevTzUTC ? dayjs.utc() : dayjs().tz(prevTimezone);
      prevTzEndDate = prevTzEndDate
        .set('hour', +endHour)
        .set('minute', +endMinute);

      const newTzStartDate = prevTzStartDate.tz(newTimezone);
      const newTzEndDate = prevTzEndDate.tz(newTimezone);
      if (
        !isDayOfWeekChanged &&
        prevTzEndDate.format('d') !== newTzStartDate.format('d')
      ) {
        isDayOfWeekChanged = true;
        dayChangeDiff =
          parseInt(newTzStartDate.format('d')) -
          parseInt(prevTzEndDate.format('d'));
        // it resolves the case if converted day becomes saturday to sunday
        // Sunday is 0 while Saturday is 6. Handling this case
        dayChangeDiff = dayChangeDiff / Math.abs(dayChangeDiff);
      }

      return {
        startTime: newTzStartDate.format('HH:mm'),
        endTime: newTzEndDate.format('HH:mm'),
        order,
      };
    });

    if (isDayOfWeekChanged) {
      const updatedEnabledDays = rotateArrayValues(
        [...schedule.enabledDays],
        dayChangeDiff
      );
      schedule.enabledDays = updatedEnabledDays;
    }

    return schedule;
  });

  return updatedSchedules;
}

export interface DayRange {
  name: string;
  symbol: string;
  ranges: { startTime: number; endTime: number; isOverflow?: boolean }[];
}

export const getWeekRanges = (schedules: SiteSchedule[]) => {
  const weekDays = getWeekdays();
  const allWeekRanges: DayRange[] = weekDays.map((day) => {
    return {
      name: day.name,
      symbol: day.symbol,
      ranges: [],
    };
  });

  schedules.forEach((sched) => {
    const enabledDays = sched.enabledDays;
    for (let i = 0; i < enabledDays.length; i++) {
      if (!enabledDays[i]) {
        continue;
      }

      for (let j = 0; j < sched.ranges.length; j++) {
        const currentRange = sched.ranges[j];
        if (currentRange.startTime === '' || currentRange.endTime === '') {
          continue;
        }
        const startTime = convertToSeconds(currentRange.startTime);
        const endTime = convertToSeconds(currentRange.endTime);
        if (endTime < startTime) {
          const currentEntry = {
            startTime,
            endTime: convertToSeconds('23:59:59'),
          };
          allWeekRanges[i].ranges.push(currentEntry);
          const nextDayIndex = (i + 1) % weekDays.length;
          const overflowEntry = {
            startTime: convertToSeconds('00:00:00'),
            endTime,
          };
          allWeekRanges[nextDayIndex].ranges.push(overflowEntry);
        } else if (endTime === startTime) {
          // 24 hours range
          const currentEntry = {
            startTime,
            endTime: convertToSeconds('23:59:59'),
          };
          allWeekRanges[i].ranges.push(currentEntry);
          if (startTime !== 0) {
            const nextDayIndex = (i + 1) % weekDays.length;
            const overflowEntry = {
              startTime: convertToSeconds('00:00:00'),
              endTime,
            };
            allWeekRanges[nextDayIndex].ranges.push(overflowEntry);
          }
        } else {
          const currentEntry = {
            startTime,
            endTime,
          };
          allWeekRanges[i].ranges.push(currentEntry);
        }
      }
    }
  });

  return allWeekRanges;
};
