/* eslint-disable max-lines */
import {
  useLocationMonitoringSchedule,
  useUpdateMotionDetectionWindow,
} from '@hakimo-ui/hakimo/data-access';
import { TimezoneSelect } from '@hakimo-ui/hakimo/feature-shared';
import { Location, SiteSchedule } from '@hakimo-ui/hakimo/types';
import {
  eventTracker,
  toast,
  useCanUpdateTenantConfig,
  usePrevious,
} from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Acknowledgements from './Acknowledgements';
import MonitoringWidget from './MonitoringWidget';
import ScheduleItem from './ScheduleItem';
import {
  getDefaultSchedule,
  getPayloadFromSchedules,
  getSchedulesFromPayload,
  getUpdatedScheduleOnTimezoneChange,
  getUpdatedSchedules,
  getUpdatedSchedulesOnDelete,
  validateAllSchedules,
} from './util';

interface Props {
  open?: boolean;
  location: Location;
}
export function SiteMonitorSchedule(props: Props) {
  const { location } = props;
  const [allSchedules, setAllSchedules] = useState<SiteSchedule[]>([
    getDefaultSchedule(),
  ]);
  const canUpdateSiteSchedule = useCanUpdateTenantConfig();
  const [initialAllSchedules, setInitialAllSchedules] = useState<
    SiteSchedule[]
  >([]);
  const [validationWarning, setValidationWarning] = useState('');
  const [timezone, setTimezone] = useState<string>();
  const [initialTimezone, setInitialTimezone] = useState<string>();
  const prevTimezone = usePrevious(timezone);
  const { isLoading, isError, error, data } = useLocationMonitoringSchedule(
    location.id
  );
  const [isEditing, setIsEditing] = useState(false);
  const onSuccess = () => {
    toast('Site schedule updated', { type: 'success' });
    setIsEditing(false);
  };
  const mutation = useUpdateMotionDetectionWindow(location.id, onSuccess);
  useEffect(() => {
    if (timezone && prevTimezone && timezone !== prevTimezone && isEditing) {
      setAllSchedules(
        getUpdatedScheduleOnTimezoneChange(allSchedules, timezone, prevTimezone)
      );
    }
  }, [timezone, prevTimezone, allSchedules, isEditing]);
  useEffect(() => {
    if (data) {
      const motionDetectionWindow = data.motionDetectionWindow;
      if (!motionDetectionWindow) {
        setAllSchedules([getDefaultSchedule()]);
        return;
      }
      const schedulesFromPayload = getSchedulesFromPayload(
        motionDetectionWindow
      );
      setAllSchedules(schedulesFromPayload);
      setInitialAllSchedules(getSchedulesFromPayload(motionDetectionWindow));
      setTimezone(motionDetectionWindow.timezone || 'UTC');
      setInitialTimezone(motionDetectionWindow.timezone || 'UTC');
    }
  }, [data]);

  const updateCustomSchedule = (i: number) => (schedule: SiteSchedule) => {
    setValidationWarning('');
    if (isEditing) {
      setAllSchedules(getUpdatedSchedules(i, schedule, allSchedules));
    }
  };
  const addCustomSchedule = () =>
    setAllSchedules([
      ...allSchedules,
      {
        ranges: [{ startTime: '', endTime: '' }],
        enabledDays: new Array(7).fill(false),
      },
    ]);

  const onDeleteSchedule = (i: number) => () => {
    if (allSchedules.length === 7) {
      setValidationWarning('');
    }
    setAllSchedules(getUpdatedSchedulesOnDelete(i, allSchedules));
  };
  const onSave = () => {
    if (timezone === '') {
      setValidationWarning('Please select a timezone');
      return;
    }
    const { isValid, message } = validateAllSchedules(allSchedules);
    if (!isValid) {
      setValidationWarning(message);
      return;
    }
    timezone &&
      mutation.mutate(getPayloadFromSchedules(allSchedules, timezone));
  };

  const handleCancel = () => {
    setAllSchedules(window.structuredClone(initialAllSchedules));
    setTimezone(initialTimezone);
    setIsEditing(false);
  };
  return (
    <div className="mx-auto flex justify-between">
      <div className="flex flex-col gap-4 px-10">
        {!location.timezone && (
          <Alert type="info">
            Please set location timezone as it will be used in monitoring
            configurations
          </Alert>
        )}
        {location.timezone &&
          timezone &&
          location.timezone.toLowerCase() !== timezone.toLowerCase() && (
            <Alert type="warning">
              Location timezone should be used in monitoring window
              configuration
            </Alert>
          )}
        {!isLoading && !data?.motionDetectionWindow && (
          <Alert type="info">
            Monitoring schedule not configured for this location. Cameras
            remains in DISARMED state.
          </Alert>
        )}
        {validationWarning && <Alert type="warning">{validationWarning}</Alert>}
        {isError && <Alert type="error">{error.message}</Alert>}
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        <Acknowledgements locationId={location.id} />
        {isLoading ? (
          <div className="flex h-24 items-center justify-center">
            <HakimoSpinner />
          </div>
        ) : (
          <div
            className={clsx(
              'flex flex-col gap-4',
              !isEditing && 'pointer-events-none opacity-80'
            )}
          >
            <TimezoneSelect value={timezone || 'UTC'} onChange={setTimezone} />

            {allSchedules.map((schedule, i) => (
              <ScheduleItem
                key={i}
                schedule={schedule}
                updateSchedule={updateCustomSchedule(i)}
                isDeletable={i > 0 && isEditing}
                isDefault={i === 0}
                onDelete={onDeleteSchedule(i)}
              />
            ))}
          </div>
        )}
        {isEditing ? (
          <>
            <div className="dark:border-dark-border-surface flex gap-2 border-t px-8"></div>
            <div className="flex flex-row gap-4 p-4">
              <Button
                variant="primary"
                onClick={onSave}
                loading={mutation.isLoading}
                onSideEffect={eventTracker('save_site_schedule')}
              >
                Save
              </Button>
              <Button
                onClick={addCustomSchedule}
                variant="outline"
                title="Add a custom schedule to have different monitoring windows on specific days"
                disabled={allSchedules.length === 7}
              >
                Add custom schedule
              </Button>
              <Button onClick={handleCancel} variant="outline">
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="dark:border-dark-border-surface flex gap-2 border-t px-8"></div>
            <div className="px-10 py-4">
              <Button
                onClick={() => setIsEditing(true)}
                variant="primary"
                title="Edit Site Monitoring Schedule"
                disabled={!canUpdateSiteSchedule}
              >
                Edit Schedule
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="mr-10">
        {data && (
          <MonitoringWidget schedules={allSchedules} timezone={timezone} />
        )}
      </div>
    </div>
  );
}
export default SiteMonitorSchedule;
