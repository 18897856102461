/* eslint-disable max-lines */
import {
  useGetAlarmNotificationPreference,
  useLocationAlarms,
  useViewLocationAlarm,
} from '@hakimo-ui/hakimo/data-access';
import { ListView } from '@hakimo-ui/hakimo/feature-shared';
import {
  LocationAlarm,
  LocationAlarmsDTO,
  StatusType,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { SplitView, SplitViewWrapper } from '@hakimo-ui/hakimo/ui-elements';
import { FilterPanel } from '@hakimo-ui/hakimo/ui-filters';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import {
  audioNotifyAtom,
  systemNotifyAtom,
  toast,
  trackFilters,
  withAuthz,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import FiltersHeader from '../shared/components/filters-header/FiltersHeader';
import { LOCATION_ALARM_QUERY_INTERVAL } from '../shared/constants';
import { useWithTimeFilterValue } from '../shared/hooks/useWithTimeFilterValue';
import { notifyNewAlarm } from '../shared/util/common';
import { getSearchParams } from '../shared/util/request-util';
import AlarmOverview from './alarm-overview/AlarmOverview';
import { defaultFilterValues, filterConfigs } from './constants';
import ListItem from './list-item/ListItem';
import {
  alarmFiltersAtom,
  alarmReturnPostResolveAtom,
  pageAtom,
  pageSizeAtom,
  timePeriodAtom,
} from './state';
import { LocationAlarmFilterValues } from './types';

export function LocationAlarmsList() {
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = useAtomValue(pageSizeAtom);
  const [period, setPeriod] = useAtom(timePeriodAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState<string>();
  const [alarmFilters, setAlarmFilters] = useAtom(alarmFiltersAtom);
  const alarmListFilters = useWithTimeFilterValue(alarmFilters, period);
  const prevData = useRef<LocationAlarmsDTO>();
  const audioNotify = useAtomValue(audioNotifyAtom);
  const alarmReturnPostResolve = useAtomValue(alarmReturnPostResolveAtom);
  const listref = useRef<HTMLDivElement>(null);
  const [locAlarmData, setLocAlarmData] = useState<LocationAlarm[]>([]);
  const viewMutation = useViewLocationAlarm();
  const systemNotifyPreference = useAtomValue(systemNotifyAtom);
  const changeSelected = (alarm?: LocationAlarm) => {
    alarm ? setSelectedId(alarm.id) : setSelectedId(undefined);
    if (alarm && alarm.id !== selectedId) {
      viewMutation.mutate({ alarmId: alarm.id });
    }
  };
  const { data: alarmNotificationSoundData } =
    useGetAlarmNotificationPreference();
  useEffect(() => {
    if (alarmFilters.camera === undefined) {
      setAlarmFilters(defaultFilterValues);
    }
  });

  const onApplyFilters = (values: LocationAlarmFilterValues) => {
    setAlarmFilters(values);
    trackFilters(values);
    setPage(1);
    setOpenFilterPanel(false);
  };

  const onData = (data: LocationAlarmsDTO) => {
    setTotal(data.total);
    setLocAlarmData(data.items);
    if (data.items.length > 0) {
      notifyNewAlarm(
        prevData.current?.items,
        data.items,
        page,
        'locationAlarm',
        audioNotify,
        systemNotifyPreference,
        alarmNotificationSoundData
      );
      prevData.current = data;
      if (!selectedId) {
        setFirstAlarmSelected(data.items);
        return;
      } else {
        const isSelectedAlarmExist = data.items.some(
          (item) => item.id === selectedId
        );
        if (!isSelectedAlarmExist) {
          toast(
            'Selected alarm may have moved to next page or is not available any more. Selecting the latest alarm'
          );
          setFirstAlarmSelected(data.items);
        }
      }
    }
  };
  const { error, isFetching } = useLocationAlarms(
    getSearchParams(page, pageSize, alarmListFilters),
    LOCATION_ALARM_QUERY_INTERVAL,
    onData,
    false,
    true
  );
  const onSelectedAlarmStatusChange = useCallback(
    (status: StatusType) => {
      if (status === 'Resolved' && alarmReturnPostResolve) {
        setSelectedId(undefined);
      }
    },
    [alarmReturnPostResolve]
  );

  const handleChangePage = (pageNum: number) => {
    setPage(pageNum);
    changeSelected(undefined);
  };

  const handlePeriodChange = (
    newPeriod: TimePeriod,
    customRange?: { start: number; end: number }
  ) => {
    setPeriod(newPeriod);
    if (newPeriod === TimePeriod.CUSTOM_RANGE && customRange) {
      setAlarmFilters((prev) => ({
        ...prev,
        time: {
          period: TimePeriod.CUSTOM_RANGE,
          customRangeStart: customRange.start,
          customRangeEnd: customRange.end,
        },
      }));
    } else {
      setAlarmFilters((prev) => ({
        ...prev,
        time: {
          period: newPeriod,
        },
      }));
    }
  };

  const setFirstAlarmSelected = (alarms: LocationAlarm[]) => {
    alarms.length > 0 && changeSelected(alarms[0]);
    listref && listref.current?.scrollTo(0, 0);
  };

  const selectedAlarm = locAlarmData.find((alarm) => alarm.id === selectedId);

  return (
    <Page title="Location Alarms" fullWidth>
      {error ? (
        <Alert type="error"> Error getting location alarms </Alert>
      ) : (
        <>
          <SplitViewWrapper>
            <SplitView>
              <div className="flex max-h-full w-[24rem] flex-col divide-y dark:divide-white/10">
                <FiltersHeader
                  period={period}
                  onChangePeriod={handlePeriodChange}
                  onClickOpenFilterPanel={() => setOpenFilterPanel(true)}
                  filterValues={alarmFilters}
                  isLoading={isFetching}
                />
                <div
                  ref={listref}
                  className="overflow-auto scroll-smooth py-4 pb-4 pl-1 pr-4"
                >
                  <ListView
                    items={locAlarmData}
                    total={total}
                    page={page}
                    pageSize={pageSize}
                    onChangePage={handleChangePage}
                    renderListItem={(alarm) => (
                      <ListItem
                        alarm={alarm}
                        selected={alarm.id === selectedId}
                        onClick={() => changeSelected(alarm)}
                      />
                    )}
                  />
                </div>
              </div>
            </SplitView>
            <SplitView>
              <div>
                {selectedAlarm && (
                  <AlarmOverview
                    key={selectedId}
                    alarm={selectedAlarm}
                    onStatusChange={onSelectedAlarmStatusChange}
                  />
                )}
              </div>
            </SplitView>
          </SplitViewWrapper>
          <FilterPanel
            title="Location Alarm Filters"
            open={openFilterPanel}
            filterConfigs={filterConfigs}
            initialFilterValues={alarmFilters}
            trackingEventNameApply="apply_location_alarms_filters"
            trackingEventNameReset="reset_location_alarms_filters"
            onClose={() => setOpenFilterPanel(false)}
            onApply={onApplyFilters}
          />
        </>
      )}
    </Page>
  );
}
export default withAuthz(LocationAlarmsList, ['location_alarm:view']);
