import { trackEvent } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { SOPStep } from './util';

interface Props {
  sopItem: SOPStep;
  locationAlarmId: string;
  stepNumber: number;
}

export function WorkflowActions(props: Props) {
  const { sopItem, locationAlarmId, stepNumber } = props;

  const logAndYesCallback = () => {
    trackEvent('sop_workflow_step_action_yes', {
      locationAlarmId,
      stepNumber: stepNumber,
      stepTitle: sopItem.title,
      stepType: sopItem.type,
    });
    sopItem.yesCallback?.();
  };

  const logAndNoCallback = () => {
    trackEvent('sop_workflow_step_action_no', {
      locationAlarmId,
      stepNumber: stepNumber,
      stepTitle: sopItem.title,
      stepType: sopItem.type,
    });
    sopItem.noCallback?.();
  };

  return (
    <div className="flex items-center justify-around gap-2 pt-5">
      <Button onClick={logAndYesCallback} variant="outline">
        Yes
      </Button>
      <Button onClick={logAndNoCallback} variant="outline">
        No
      </Button>
    </div>
  );
}
