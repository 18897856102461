/* eslint-disable no-console */
import { WSConnection, WsConnectionState } from '@hakimo-ui/hakimo/types';
import { ScanWsContext } from '@hakimo-ui/hakimo/util';
import { proxy, Remote, wrap } from 'comlink';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { WebSocketClient } from './ScanWsClient.worker';

interface Props {
  url: string;
  getAccessToken?: () => Promise<string>;
  children: ReactNode;
}

export function ScanWsProvider(props: Props) {
  const { children, url, getAccessToken } = props;

  const [wsConnectionState, setWsConnectionState] = useState<WsConnectionState>(
    WsConnectionState.PENDING
  );

  // Refs for the worker instance and the wrapped WebSocketClient
  const workerRef = useRef<Worker | null>(null);
  const clientRef = useRef<Remote<WebSocketClient> | null>(null);

  // Subscribers registry: each message type maps to a set of callbacks
  const subscribersRef = useRef<Record<string, Set<(data: any) => void>>>({});

  const connect = useCallback(
    async (client: Remote<WebSocketClient>) => {
      try {
        let accessToken: string | undefined;
        if (getAccessToken) {
          accessToken = await getAccessToken();
        }
        await client.connect(url, accessToken);
        setWsConnectionState(WsConnectionState.CONNECTED);
      } catch (error) {
        console.error('Error connecting to WebSocket', error);
        setWsConnectionState(WsConnectionState.ERROR);
      }
    },
    [getAccessToken, url]
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Worker = require('./ScanWsClient.worker.ts');
    const workerInstance = new Worker();
    const client = wrap<WebSocketClient>(workerInstance);
    workerRef.current = workerInstance;
    clientRef.current = client;

    client.setMessageHandler(
      // Use Comlink.proxy to wrap the callback function
      proxy((message: any) => {
        try {
          const { type, data } = message;
          const subscribers = subscribersRef.current[type];
          if (subscribers) {
            subscribers.forEach((callback) => callback(data));
          }
        } catch (error) {
          console.error('Error handling message:', { message, error });
        }
      })
    );
    client.setConnectionStatusHandler(
      proxy((data) => {
        setWsConnectionState(data.status);
      })
    );

    connect(client);

    return () => {
      // Cleanup: disconnect the client, and terminate the worker.
      if (clientRef.current) {
        clientRef.current.disconnect();
      }
      workerInstance.terminate();
    };
  }, [connect]);

  const disconnect = () => {
    clientRef.current?.disconnect();
    workerRef.current?.terminate();
  };

  // Function to subscribe to messages for a given topic (i.e. message.type)
  // Returns an unsubscribe function.
  const subscribe = (
    topic: string,
    callback: (data: any) => void
  ): (() => void) => {
    if (!subscribersRef.current[topic]) {
      subscribersRef.current[topic] = new Set();
    }
    subscribersRef.current[topic].add(callback);
    return () => {
      subscribersRef.current[topic]?.delete(callback);
    };
  };

  const send = (message: any) => {
    if (clientRef.current) {
      const msgStr =
        typeof message === 'string' ? message : JSON.stringify(message);
      clientRef.current.send(msgStr);
    } else {
      console.error('WebSocket client not initialized');
    }
  };

  const contextVal: WSConnection = {
    connectionState: wsConnectionState,
    subscribe,
    send,
    disconnect,
  };

  return (
    <ScanWsContext.Provider value={contextVal}>
      {children}
    </ScanWsContext.Provider>
  );
}
