import { useUpdateLocationContacts } from '@hakimo-ui/hakimo/data-access';
import { LocationContact } from '@hakimo-ui/hakimo/types';
import { deepEqual, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { useMemo } from 'react';
import { ADD_CONTACT_ID_PREFIX } from './utils';

interface Props {
  oldData: LocationContact[];
  newData: LocationContact[];
  onAddNewContact: () => void;
  locationId: number;
  tenantId: string;
}

export function Actions(props: Props) {
  const { oldData, newData, onAddNewContact, locationId, tenantId } = props;
  const isChangesActive = useMemo(() => {
    const sortedOldData = [...oldData].sort((a, b) => b.priority - a.priority);
    return !deepEqual(sortedOldData, newData);
  }, [oldData, newData]);

  const onSuccess = () => {
    toast('Location contacts updated successfully', { type: 'success' });
  };
  const mutation = useUpdateLocationContacts(locationId, onSuccess);

  const onSave = () => {
    const contacts = newData.map((item) => {
      const obj: Partial<LocationContact> = {
        name: item.name,
        email: item.email,
        phoneNumber: item.phoneNumber,
        priority: item.priority,
        tag: item.tag,
      };
      if (!String(item.id).startsWith(ADD_CONTACT_ID_PREFIX)) {
        obj['id'] = item.id;
      }
      return obj;
    });
    const payload = {
      locationId,
      tenantId,
      contacts,
    };
    mutation.mutate(payload);
  };

  return (
    <div className="mt-4 space-x-4">
      {mutation.isError && <Alert type="error">{mutation.error.message}</Alert>}
      {isChangesActive && (
        <Button
          variant="primary"
          onClick={onSave}
          disabled={mutation.isLoading}
          loading={mutation.isLoading}
        >
          Save Changes
        </Button>
      )}
      <Button
        variant="outline"
        onClick={onAddNewContact}
        disabled={mutation.isLoading}
      >
        Add new Contact
      </Button>
    </div>
  );
}

export default Actions;
