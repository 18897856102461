import { DetectionType } from '@hakimo-ui/hakimo/types';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';

interface Props {
  onSubmit: (detectionType: DetectionType) => void;
}

function MotionDetector({ onSubmit }: Props) {
  const onDetection = (type: DetectionType) => () => onSubmit(type);

  return (
    <div className="flex flex-wrap items-center gap-4">
      <span>What did you see?</span>
      <div className="flex flex-wrap gap-4">
        <Button
          onClick={onDetection(DetectionType.PERSON)}
          title="Person"
          variant="outline"
          onSideEffect={eventTracker('person_detected')}
          autoFocus
        >
          Person
        </Button>
        <Button
          onClick={onDetection(DetectionType.VEHICLE)}
          title="Vehicle"
          onSideEffect={eventTracker('vehicle_detected')}
        >
          Vehicle
        </Button>
        <Button
          onClick={onDetection(DetectionType.INCORRECT_DETECTION)}
          title="Incorrect Detection"
          onSideEffect={eventTracker('incorrect_detection')}
        >
          Incorrect Detection
        </Button>
      </div>
    </div>
  );
}

export default MotionDetector;
