import { LocationAlarmIncidentDTO, StatusType } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import ResolveIncidentModal from './ResolveIncidentModal';

interface Props {
  incident: LocationAlarmIncidentDTO;
  locationAlarmId: string;
  onStatusChange?: (status: StatusType) => void;
}

export function LocationAlarmIncident(props: Props) {
  const { incident, locationAlarmId, onStatusChange } = props;

  const { title, description, incidentStartTimeUtc } = incident;

  const [showResolveModal, setShowResolveModal] = useState(false);

  const formattedLocalTime = incidentStartTimeUtc
    ? new Date(incidentStartTimeUtc).toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'medium',
      })
    : '';

  const onCloseModal = () => setShowResolveModal(false);

  const showModal = () => setShowResolveModal(true);

  return (
    <>
      <div className="grid grid-cols-12 rounded-md border border-red-200 bg-red-50 px-4 py-2 text-sm text-red-800 dark:border-red-500/50 dark:bg-red-700/50 dark:text-red-200">
        <div className="col-span-1">
          <ExclamationTriangleIcon className="h-10 w-10" />
        </div>
        <div className="col-span-9">
          <div className="flex gap-4">
            <span className="font-bold">{title}</span>
            <span>{description}</span>
          </div>
          <div className="text-xs">Reported at: {formattedLocalTime}</div>
        </div>
        <div className="col-span-2 text-end">
          <Button onClick={showModal} variant="success">
            Resolve
          </Button>
        </div>
      </div>
      {showResolveModal && (
        <ResolveIncidentModal
          locationAlarmId={locationAlarmId}
          locationIncident={incident}
          onClose={onCloseModal}
          onStatusChange={onStatusChange}
        />
      )}
    </>
  );
}

export default LocationAlarmIncident;
