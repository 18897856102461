import { UpdateSiteDisarmWindowPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateLocationMonitoringDisarmWindow(
  locationId: string,
  onSuccess: () => void
) {
  const url = `/v2/orm/locations/${locationId}/monitoring/disarm/window`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, UpdateSiteDisarmWindowPayload>(
    request,
    {
      onSuccessInvalidationKeys: [['location_monitoring_window']],
      onSuccess,
    }
  );
}

export default useUpdateLocationMonitoringDisarmWindow;
