import { Situation, SituationColor } from '@hakimo-ui/hakimo/types';
import clsx from 'clsx';

interface Props {
  exceptions?: string[];
  notes?: string[];
  situations?: Situation[];
  onAction?: (actionText: string) => void;
}

export function SOPFirstStep(props: Props) {
  const { exceptions, notes, situations, onAction } = props;

  const onSituationSelect = (situation: Situation) => {
    onAction?.(situation.label);
  };

  return (
    <div className="text-onlight-text-2 dark:text-ondark-text-2 dark:divide-ondark-line-2 flex flex-col space-y-2 divide-y text-sm">
      {exceptions && exceptions.length > 0 && (
        <div className="py-2">
          <div className="text-primary-800 dark:text-primary-400 mb-1 font-sans font-semibold">
            Exceptions (Do not escalate if observed)
          </div>
          <ul className="list-disc space-y-2">
            {exceptions.map((exception, i) => (
              <li key={i} className="ml-4 list-item">
                {exception}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Notes Section */}
      {notes && notes.length > 0 && (
        <div className="py-2">
          <div className="text-primary-800 dark:text-primary-400 mb-1 font-sans font-semibold">
            Notes
          </div>
          <ul className="list-disc space-y-2">
            {notes.map((note, i) => (
              <li key={i} className="ml-4 list-item">
                {note}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Situations Section (Scrollable) */}
      {situations && (
        <div className="py-2">
          <div className="text-primary-800 dark:text-primary-400 mb-1 font-sans font-semibold">
            Situations (Select if observed)
          </div>
          <div className="relative mt-2 pb-2">
            <div className="from-ondark-text-2 pointer-events-none absolute inset-y-0 right-0 w-[2px] bg-gradient-to-b to-transparent"></div>
            <div className="from-ondark-text-2 pointer-events-none absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r to-transparent"></div>

            <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 flex max-h-36 flex-wrap gap-2 overflow-y-auto py-1 pr-2">
              {situations.map((situation, i) => (
                <button
                  key={i}
                  className={clsx(
                    'dark:bg-ondark-bg-2 bg-onlight-bg-2 border-onlight-line-3 dark:border-ondark-line-2 flex cursor-pointer items-center gap-2 rounded-md border p-1 px-2 text-sm hover:shadow-md',
                    situation.color === SituationColor.RED &&
                      'text-rose-600 hover:text-rose-700 dark:text-rose-400 dark:hover:text-rose-300',
                    situation.color === SituationColor.GREEN &&
                      'text-teal-600 hover:text-teal-700 dark:text-teal-400 dark:hover:text-teal-300',
                    situation.color === SituationColor.BLUE &&
                      'text-primary-500 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300',
                    'enabled:hover:bg-gray-200 dark:enabled:hover:bg-gray-700'
                  )}
                  onClick={() =>
                    onSituationSelect && onSituationSelect(situation)
                  }
                >
                  {situation.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
