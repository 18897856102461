import AudioRecorder from '../audio-recorder/AudioRecorder';
import AutoTextToSpeech from '../audio-recorder/auto-text-to-speech/AutoTextToSpeech';
import { SOPFirstStep } from './SOPFirstStep';
import { SOPStep, SOPStepType } from './util';

interface Props {
  sopItem: SOPStep;
  cameraName?: string;
  cameraId: string;
  logTalkdown: (cameraId: string, alarmId?: string) => void;
  performTalkdown: (audioFile: File) => void;
  isTalkdownInProcess?: boolean;
  locationAlarmId: string;
}

export function WorkflowBody(props: Props) {
  const {
    sopItem,
    performTalkdown,
    cameraId,
    logTalkdown,
    cameraName,
    isTalkdownInProcess,
    locationAlarmId,
  } = props;

  const title =
    sopItem.type === SOPStepType.FIRST_STEP ? '' : 'Perform the talkdown';

  return (
    <div className="py-2">
      {title && (
        <div className="text-primary-800 dark:text-primary-400 my-2 font-sans text-sm font-semibold">
          {title}
        </div>
      )}
      {sopItem.type === SOPStepType.FIRST_STEP && (
        <SOPFirstStep
          exceptions={sopItem.exceptions}
          notes={sopItem.notes}
          situations={sopItem.situations}
          onAction={sopItem.actionCallback}
        />
      )}
      {sopItem.type === SOPStepType.STATIC_TALKDOWN && (
        <div className="text-sm italic">
          <AutoTextToSpeech
            text={sopItem.talkdownText || ''}
            performTalkdown={performTalkdown}
            isLoading={isTalkdownInProcess}
          />
        </div>
      )}
      {sopItem.type === SOPStepType.DYANMIC_TALKDOWN && (
        <div className="flex flex-wrap items-center justify-around gap-2 text-sm">
          <div className="text-primary-800 dark:text-primary-400 my-2">
            Assess identification details such as clothes color and perform the
            talkdown.
          </div>
          <span className="italic">{sopItem.talkdownText || ''}</span>
          <AudioRecorder
            cameraId={cameraId}
            cameraName={cameraName}
            onTalkdown={logTalkdown}
            locAlarmId={locationAlarmId}
          />
        </div>
      )}
    </div>
  );
}
