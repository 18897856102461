import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Toggle } from '@hakimo-ui/shared/ui-base';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CameraGrid from './cameras-grid/CameraGrid';
import CamerasList from './cameras-list/CamerasList';
import { CamerasViewMode } from './constants';

export function CamerasRoot() {
  const [mode, setMode] = useState(CamerasViewMode.LIST_VIEW);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const toggleMode = (val: boolean) => {
    val
      ? setMode(CamerasViewMode.GRID_VIEW)
      : setMode(CamerasViewMode.LIST_VIEW);
    if (val) {
      setSearchParams([]);
    }
  };

  return (
    <Page title="Cameras" fullWidth>
      <div className="flex justify-end gap-4 pb-1">
        <Toggle
          enabled={mode === CamerasViewMode.GRID_VIEW}
          onChange={toggleMode}
          DisabledIcon={ListBulletIcon}
          EnabledIcon={Squares2X2Icon}
          label="Multi Cam View"
        />
      </div>
      <>
        {mode === CamerasViewMode.LIST_VIEW && <CamerasList />}
        {mode === CamerasViewMode.GRID_VIEW && <CameraGrid />}
      </>
    </Page>
  );
}

export default CamerasRoot;
