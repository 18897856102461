import { RedShieldIcon } from '@hakimo-ui/hakimo/ui-elements';
import { Button, Tooltip } from '@hakimo-ui/shared/ui-base';
import {
  ArrowLeftIcon,
  ClockIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {
  DayLocationMonitoringConfig,
  formatTextWithHeading,
  getMonitoringConfigText,
} from './util';

interface Props {
  totalSteps: number;
  currentStep: number;
  onStepChange?: (step: number) => void;
  locationMonitoringConfigToday?: DayLocationMonitoringConfig;
  isZeroToleranceSite?: boolean;
  exceptions?: string[];
  notes?: string[];
  showExceptionsAndNotes?: boolean;
}

export function WorkflowHeader(props: Props) {
  const {
    onStepChange,
    currentStep,
    locationMonitoringConfigToday,
    isZeroToleranceSite,
    exceptions = [],
    notes = [],
    showExceptionsAndNotes = false,
  } = props;

  const onBackClick = () => {
    onStepChange?.(currentStep - 1);
  };

  const monitoringConfigString = getMonitoringConfigText(
    locationMonitoringConfigToday
  );

  const exceptionsText = formatTextWithHeading(exceptions, 'Exceptions');
  const notesText = formatTextWithHeading(notes, 'Notes');

  return (
    <div className="left-0 top-0 space-x-2">
      {currentStep > 0 && (
        <Button variant="icon" onClick={onBackClick}>
          <ArrowLeftIcon className="h-5 w-5" />
        </Button>
      )}
      {isZeroToleranceSite && (
        <Tooltip
          text="Zero Tolerance Site"
          position="bottom-right"
          size="medium"
          colorModifier="info"
        >
          <RedShieldIcon className="h-6 w-6 fill-red-800 dark:fill-red-600" />
        </Tooltip>
      )}
      <Tooltip
        text={monitoringConfigString}
        position="bottom-right"
        size="medium"
        colorModifier="info"
      >
        <ClockIcon className="h-6 w-6" />
      </Tooltip>

      {showExceptionsAndNotes && exceptions.length > 0 && (
        <Tooltip
          text={exceptionsText}
          position="bottom-right"
          size="large"
          colorModifier="info"
        >
          <ExclamationTriangleIcon className="text-hakimo-yellow h-6 w-6" />
        </Tooltip>
      )}

      {showExceptionsAndNotes && notes.length > 0 && (
        <Tooltip
          text={notesText}
          position="bottom-right"
          size="medium"
          colorModifier="info"
        >
          <DocumentTextIcon className="text-primary-500 dark:text-primary-300 h-6 w-6" />
        </Tooltip>
      )}
    </div>
  );
}

export default WorkflowHeader;
