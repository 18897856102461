import ContactSMS from './ContactSMS';
import { VoiceCall } from './VoiceCall';
interface Props {
  toNumber: string;
  toName: string;
  locationAlarmId?: string;
  locationTenantId?: string;
  tag?: string;
}

function ContactConnect(props: Props) {
  const { toNumber, toName, locationAlarmId, locationTenantId, tag } = props;

  return (
    <div className="flex items-center justify-between p-2">
      <div>
        <div className="text-start">{toName}</div>
        <div className="flex items-center gap-2">
          <div className="text-xs">{toNumber}</div>
          {tag && (
            <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 border-onlight-line-2 dark:border-ondark-line-2 text-primary-500 dark:text-primary-400 flex cursor-pointer items-center gap-1 rounded-md border px-2 text-sm">
              {tag}
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2">
        <VoiceCall
          toNumber={toNumber}
          toName={toName}
          locationAlarmId={locationAlarmId}
          locationTenantId={locationTenantId}
        />
        <ContactSMS toNumber={toNumber} toName={toName} />
      </div>
    </div>
  );
}

export default ContactConnect;

/**
 *
 *
 * <div
            key={`${contact.phoneNumber}-${contact.name}`}
            className="flex items-center justify-between pt-1"
          >
            <div className="flex flex-col gap-1 text-xs">
              <span className="text-sm">{contact.name}</span>
              <span>{contact.phoneNumber}</span>
              {contact.role && <span>({contact.role})</span>}
            </div>
            <Button
              variant="icon"
              onClick={onCall(contact)}
              onSideEffect={eventTracker('call_apartment_contact')}
            >
              <PhoneIcon className="text-primary-500 mr-2 h-4 w-4" />
            </Button>
          </div>
 */
