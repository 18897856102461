import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPManager } from '@hakimo-ui/hakimo/feature-shared';
import { SOPDTO } from '@hakimo-ui/hakimo/types';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  locationId: string;
  tenantId: string;
}

export function SOPRoot(props: Props) {
  const { locationId, tenantId } = props;
  const [sop, setSOP] = useState<SOPDTO>();
  const { isLoading, isRefetching } = useSOP(
    {
      locationId,
    },
    ({ sop_workflow, id }) => {
      setSOP({ sop_workflow, id });
    }
  );

  if (isLoading || isRefetching) {
    return (
      <span className="flex h-[26rem] w-full items-center justify-center">
        <HakimoSpinner />
      </span>
    );
  }

  return (
    <SOPManager
      sopWorkflow={sop?.sop_workflow}
      sopId={sop?.id ?? ''}
      locationId={locationId}
      tenantId={tenantId}
    />
  );
}

export default SOPRoot;
