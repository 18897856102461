import {
  FullScreenExitIcon,
  FullScreenIcon,
  MenuDropdown,
  MenuItem,
} from '@hakimo-ui/hakimo/ui-elements';
import { Button } from '@hakimo-ui/shared/ui-base';
import {
  ArrowPathRoundedSquareIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import { GridConfig } from '../../types/common';

interface Props {
  toggleFullScreen: () => void;
  isFullScreen: boolean;
  onArrangeSmartly: () => void;
  gridConfig: GridConfig;
  updateGridConfig: (config: GridConfig) => void;
}

export function ScanToolbar(props: Props) {
  const {
    toggleFullScreen,
    isFullScreen,
    onArrangeSmartly,
    gridConfig,
    updateGridConfig,
  } = props;

  const gridMenuItems: MenuItem[] = [
    {
      name: '3 x 3 grid',
      onClick: () => updateGridConfig({ cols: 3, rows: 3 }),
      className:
        gridConfig.cols === 3 && gridConfig.rows === 3
          ? 'text-primary-500 dark:text-primary-500'
          : '',
    },
    {
      name: '4 x 4 grid',
      onClick: () => updateGridConfig({ cols: 4, rows: 4 }),
      className:
        gridConfig.cols === 4 && gridConfig.rows === 4
          ? 'text-primary-500 dark:text-primary-500'
          : '',
    },
    {
      name: '5 x 5 grid',
      onClick: () => updateGridConfig({ cols: 5, rows: 5 }),
      className:
        gridConfig.cols === 5 && gridConfig.rows === 5
          ? 'text-primary-500 dark:text-primary-500'
          : '',
    },
    {
      name: '6 x 6 grid',
      onClick: () => updateGridConfig({ cols: 6, rows: 6 }),
      className:
        gridConfig.cols === 6 && gridConfig.rows === 6
          ? 'text-primary-500 dark:text-primary-500'
          : '',
    },
  ];

  const menuButton = <SquaresPlusIcon className="h-5 w-5" />;

  return (
    <div className="flex flex-grow items-center justify-end gap-2">
      <MenuDropdown
        position="top"
        items={gridMenuItems}
        menuButton={menuButton}
      />
      <Button variant="icon" onClick={onArrangeSmartly} title="Smart Rearrange">
        <ArrowPathRoundedSquareIcon className="h-5 w-5" />
      </Button>
      {/* TODO: remove hidden css and workout a way to adjust full screen with FullViewModal */}
      <Button variant="icon" onClick={toggleFullScreen} className="hidden">
        {isFullScreen ? (
          <FullScreenExitIcon className="dark:fill-ondark-text-1 h-7 w-7" />
        ) : (
          <FullScreenIcon className="dark:fill-ondark-text-1 h-7 w-7" />
        )}
      </Button>
    </div>
  );
}

export default ScanToolbar;
