/* eslint-disable max-lines */
import { SiteSchedule } from '@hakimo-ui/hakimo/types';
import { Button, InputField } from '@hakimo-ui/shared/ui-base';
import {
  BookmarkIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { WeekdayActions } from './WeekdayActions';
import { convertToSeconds } from './util';

interface Props {
  schedule: SiteSchedule;
  updateSchedule: (schedule: SiteSchedule) => void;
  isDeletable?: boolean;
  onDelete?: () => void;
  isDefault?: boolean;
}

export function ScheduleItem(props: Props) {
  const { schedule, updateSchedule, isDeletable, onDelete, isDefault } = props;

  const { ranges, enabledDays } = schedule;

  const onStartChange =
    (rangeIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRanges = [...schedule.ranges];
      updatedRanges[rangeIndex] = {
        ...updatedRanges[rangeIndex],
        startTime: e.target.value,
      };
      updateSchedule({ ...schedule, ranges: updatedRanges });
    };

  const onEndChange =
    (rangeIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRanges = [...schedule.ranges];
      updatedRanges[rangeIndex] = {
        ...updatedRanges[rangeIndex],
        endTime: e.target.value,
      };
      updateSchedule({ ...schedule, ranges: updatedRanges });
    };

  const updateEnabledDays = (days: boolean[]) =>
    updateSchedule({ ...schedule, enabledDays: days });

  const onAddRange = () => {
    const updatedRanges = [...schedule.ranges];
    updatedRanges.push({
      startTime: '',
      endTime: '',
      order: schedule.ranges.length - 1,
    });
    updateSchedule({ ...schedule, ranges: updatedRanges });
  };

  const onDeleteRange = (index: number) => () => {
    const updatedRanges = [...schedule.ranges];
    updatedRanges.splice(index, 1);
    updateSchedule({ ...schedule, ranges: updatedRanges });
  };

  const getExtraInfo = (range: {
    startTime: string;
    endTime: string;
    order?: number;
  }) => {
    const { endTime, startTime } = range;
    if (!startTime || !endTime) {
      return '';
    }
    const is24HrWindow = startTime === endTime;
    let isNextDay = convertToSeconds(startTime) > convertToSeconds(endTime);

    if (is24HrWindow && convertToSeconds(startTime) !== 0) {
      isNextDay = true;
    }

    const extraInfo = `${isNextDay ? '+1 Day' : ''}${
      is24HrWindow ? '(24 Hrs)' : ''
    }`;
    return extraInfo;
  };

  return (
    <div
      className={clsx(
        'bg-onlight-bg-2 dark:bg-ondark-bg-2 dark:border-ondark-line-3 relative flex items-center gap-4 rounded-md border p-4 pb-2 shadow-sm transition-all duration-300 ease-in-out',
        ranges.length > 1 && 'flex-col'
      )}
    >
      {isDeletable && (
        <Button
          variant="icon"
          onClick={onDelete}
          title="Delete schedule"
          className="absolute right-0 top-0 ml-4"
        >
          <XMarkIcon className="h-5 w-5" />
        </Button>
      )}
      {isDefault && (
        <BookmarkIcon className="text-primary-500 absolute left-0 top-0 h-5 w-5" />
      )}
      <div className="flex divide-x-2">
        {ranges.map((range, i) => (
          <div className="flex items-center justify-center gap-4 px-4" key={i}>
            <div className="w-32">
              <InputField
                label="Start time"
                onChange={onStartChange(i)}
                type="time"
                value={range.startTime}
                required
              />
            </div>
            <div className="relative w-32">
              <span className="text-primary-500 absolute left-16 top-1 whitespace-nowrap text-xs">
                {getExtraInfo(range)}
              </span>
              <InputField
                label="End time"
                onChange={onEndChange(i)}
                type="time"
                value={range.endTime}
              />
              {i > 0 && (
                <Button
                  variant="icon"
                  onClick={onDeleteRange(i)}
                  title="Delete range"
                  className="absolute -right-6 top-1/2 ml-4"
                >
                  <TrashIcon className="h-4 w-4 text-red-500" />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between gap-4">
        <WeekdayActions
          selectedDays={enabledDays}
          updateSelectedDays={updateEnabledDays}
          disableSelectedDays={!isDeletable}
        />
        {ranges.length < 2 && (
          <Button
            variant="icon"
            onClick={onAddRange}
            title="Add range"
            className="flex flex-1 gap-1 text-sm"
          >
            <PlusCircleIcon className="h-5 w-5" />
            <span>Add range</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default ScheduleItem;
