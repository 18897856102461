import {
  getUserListSearchParams,
  useUsers,
} from '@hakimo-ui/hakimo/data-access';
import { UserListFilters } from '@hakimo-ui/hakimo/types';
import {
  useCanAddUser,
  useExportCsv,
  useIsSupportRole,
} from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { ReactElement, useState } from 'react';
import { getFormattedExportData, isNotDefaultFilter } from '../utils';

interface Props {
  isRefetching: boolean;
  userListFilters: UserListFilters;
  onOpenAddUserModal: () => void;
  onOpenFilterPanel: () => void;
  onOpenMSPLocationModal: () => void;
  isRowsSelected: boolean;
  canUpdateMSPLocations: boolean;
  isDataValid: boolean;
}
function UserListHeader(props: Props): ReactElement {
  const {
    isRefetching,
    isRowsSelected,
    userListFilters,
    onOpenFilterPanel,
    onOpenAddUserModal,
    onOpenMSPLocationModal,
    canUpdateMSPLocations,
    isDataValid,
  } = props;
  const canAdd = useCanAddUser();

  const [isExporting, setIsExporting] = useState(false);
  const exportSearchParams = getUserListSearchParams({
    filters: userListFilters,
  });
  const { refetch: fetchExportData } = useUsers(
    exportSearchParams,
    undefined,
    false
  );

  const { exportCsv } = useExportCsv();
  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { data } = await fetchExportData();
      if (!data?.items) {
        throw new Error('Data Fetching Failed');
      }

      const formattedData = getFormattedExportData(data);
      if (!Array.isArray(formattedData) || formattedData.length === 0) {
        throw new Error('Formatted data is invalid or empty');
      }
      exportCsv({
        formattedData,
        fileName: 'hakimo-users-export.csv',
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error(
        `Export Failed: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    } finally {
      setIsExporting(false);
    }
  };
  const isSupportRole = useIsSupportRole();
  const DisableExport = !isDataValid || !isSupportRole;

  return (
    <div className="dark:bg-dark-bg flex items-center justify-between border-b bg-white p-4 dark:border-0">
      <span>
        {canAdd && (
          <Button variant="primary" onClick={onOpenAddUserModal}>
            Add User
            <span className="sr-only">Add User</span>
          </Button>
        )}
        {canUpdateMSPLocations && (
          <Button
            variant="primary"
            onClick={onOpenMSPLocationModal}
            disabled={!isRowsSelected}
            className="ml-2"
          >
            Assign MSP Locations
            <span className="sr-only">Assign MSP locations</span>
          </Button>
        )}
      </span>
      <div className="flex items-center gap-2">
        {isRefetching && <HakimoSpinner />}
        <span className="">
          <Button
            variant="icon"
            onClick={handleExport}
            loading={isExporting}
            title="Download Data"
            disabled={DisableExport}
          >
            <ArrowDownTrayIcon className="w-5" />
          </Button>
        </span>
        <Button
          variant="icon"
          badge={isNotDefaultFilter(userListFilters)}
          onClick={onOpenFilterPanel}
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default UserListHeader;
