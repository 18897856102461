import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import LocationContacts from '../../location-contacts/LocationContacts';

interface Props {
  siteAddress: string;
  escalationPoints: string[];
  escalationProtocol: string[];
  siteGoogleMapLocation: string;
  locationId: string;
  locationAlarmId: string;
  locationTenantId?: string;
}

export function Escalation(props: Props) {
  const {
    siteAddress,
    escalationPoints,
    escalationProtocol,
    siteGoogleMapLocation,
    locationId,
    locationAlarmId,
    locationTenantId,
  } = props;

  return (
    <div className="flex flex-col gap-4 px-2 pt-2">
      <h2 className="text-center font-semibold text-red-700/80 dark:text-red-400/80">
        Escalate now to concerned authorities
      </h2>
      <div className="flex gap-2">
        <span className="text-primary-800 dark:text-primary-400 font-sans font-semibold">
          Site address:
        </span>
        <a
          href={siteGoogleMapLocation}
          target="_blank"
          rel="noreferrer"
          title="Open in Google Maps"
          className="text-primary-500 dark:text-primary-300 text-sm hover:underline"
        >
          {siteAddress}
          <ArrowTopRightOnSquareIcon className="h-4 w-4" />
        </a>
      </div>
      {escalationProtocol.length > 0 && (
        <div className="space-y-2">
          <div className="text-primary-800 dark:text-primary-400 font-sans font-semibold">
            Escalation Protocol:
          </div>
          <ol className="list-decimal space-y-1 text-sm">
            {escalationProtocol.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ol>
        </div>
      )}
      <div className="space-y-2">
        <details>
          <summary className="text-primary-800 dark:text-primary-400 cursor-pointer font-sans font-semibold">
            Call Checklist
          </summary>
          <ol className="mt-2 list-decimal space-y-1 text-sm">
            {escalationPoints.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ol>
        </details>
      </div>
      <div className="pt-2">
        <LocationContacts
          locationId={+locationId}
          locationAlarmId={locationAlarmId}
          locationTenantId={locationTenantId}
        />
      </div>
    </div>
  );
}

export default Escalation;
