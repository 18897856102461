/* eslint-disable max-lines */
import {
  NEGATIVE_FILTER_TOKEN,
  SEPARATOR,
} from '@hakimo-ui/hakimo/data-access';
import {
  FilterValues,
  TimeFilterValue,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export function getSearchParams(
  page: number,
  pageSize: number,
  values: Partial<FilterValues>,
  tenants?: Selectable[]
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));

  if (values.location && values.location.values.length > 0) {
    const query = getFilterQuery(
      values.location.values.map((item) => item.id),
      values.location.negative
    );
    params.append('locationIds', query);
  }

  if (values.camera && values.camera.values.length > 0) {
    const query = getFilterQuery(
      values.camera.values.map((item) => item.id),
      values.camera.negative
    );
    params.append('camera_ids', query);
  }

  if (values.sourceEntity && values.sourceEntity.values.length > 0) {
    const query = getFilterQuery(
      values.sourceEntity.values.map((item) => item.name),
      values.sourceEntity.negative
    );
    params.append('sourceEntities', query);
  }

  if (values.type && values.type.values.length > 0) {
    const query = getFilterQuery(
      values.type.values.map((item) => item.name),
      values.type.negative
    );
    params.append('types', query);
  }

  if (values.tenant && values.tenant.values.length > 0) {
    const query = getFilterQuery(
      values.tenant.values.map((item) => item.id),
      values.tenant.negative
    );
    params.append('tenants', query);
  }

  if (values.status && values.status.values.length > 0) {
    const statusQuery: string[] = [...values.status.values];
    const query = getFilterQuery(statusQuery, values.status.negative);
    params.append('statuses', query);
  }

  if (values.employee && values.employee.values.length > 0) {
    const empQuery = values.employee.values.map((item) => {
      return item.id;
    });
    const query = getFilterQuery(empQuery, values.employee.negative);
    params.append('employeeIds', query);
  }

  if (values.sourceSystem && values.sourceSystem.length > 0) {
    const query = getFilterQuery(
      values.sourceSystem.map((item) => item.name),
      false
    );
    params.append('sourceSystems', query);
  }

  if (values.time && values.time.period !== TimePeriod.ALL_TIME) {
    if (values.time.period === TimePeriod.CUSTOM_RANGE) {
      const [startTime, endTime] = getStartEndTimes(values.time);
      if (startTime) {
        params.append('dateFrom', String(startTime));
      }
      if (endTime) {
        params.append('dateTo', String(endTime));
      }
    } else {
      const [minutes, hours] = getMHFromTimePeriod(values.time.period);
      if (hours) {
        params.append('relativeTimeHours', String(hours));
      }
      if (minutes) {
        params.append('relativeTimeMinutes', String(minutes));
      }
    }
  }

  if (values.agedAlarm && values.agedAlarm.name !== TimePeriod.ALL_TIME) {
    let totalMinutes = 0;
    if (
      values.agedAlarm.name === TimePeriod.CUSTOM_TIME &&
      values.agedAlarm.customTime !== undefined
    ) {
      totalMinutes = +values.agedAlarm?.customTime;
    } else {
      const [minutes, hours] = getMHFromTimePeriod(
        values.agedAlarm.name as TimePeriod
      );
      totalMinutes = hours * 60 + minutes;
    }

    params.append('agedAlarmTimeMinutes', String(totalMinutes));
  }

  if (values.mannedLocation) {
    params.append('showMannedLocationAlarms', values.mannedLocation);
  }

  if (tenants) {
    params.append('tenants', tenants.map((item) => item.id).join(SEPARATOR));
  }

  return params.toString();
}
/*
Return Minutes, Hours from time period used for relative time API request
*/
function getMHFromTimePeriod(timePeriod: TimePeriod): [number, number] {
  switch (timePeriod) {
    case TimePeriod.PAST_MINUTE:
      return [1, 0];
    case TimePeriod.PAST_HALF_HOUR:
      return [0, 0.5];
    case TimePeriod.PAST_HOUR:
      return [0, 1];
    case TimePeriod.PAST_SIX_HOURS:
      return [0, 6];
    case TimePeriod.PAST_12_HOURS:
      return [0, 12];
    case TimePeriod.PAST_24_HOURS:
      return [0, 24];
    case TimePeriod.PAST_WEEK:
      return [0, 24 * 7];
    case TimePeriod.PAST_MONTH:
      return [0, 24 * 30];
    case TimePeriod.PAST_THREE_MONTHS:
      return [0, 24 * 30 * 3];
    default:
      return [0, 0];
  }
}
function getFilterQuery(values: string[], negative: boolean): string {
  const query = values;
  if (negative) {
    query.push(NEGATIVE_FILTER_TOKEN);
  }
  return query.join(SEPARATOR);
}

function getStartEndTimes(time: TimeFilterValue): [number, number] {
  let endTime: number;
  let startTime: number;

  if (time.period === TimePeriod.CUSTOM_RANGE) {
    startTime = new Date(time.customRangeStart).getTime();
    endTime = new Date(time.customRangeEnd).getTime();
  } else {
    endTime = Date.now();
    if (time.period === TimePeriod.PAST_MINUTE) {
      startTime = endTime - 60 * 1000;
    } else if (time.period === TimePeriod.PAST_HOUR) {
      startTime = endTime - 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_SIX_HOURS) {
      startTime = endTime - 6 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_24_HOURS) {
      startTime = endTime - 24 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_WEEK) {
      startTime = endTime - 7 * 24 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_MONTH) {
      startTime = endTime - 30 * 24 * 60 * 60 * 1000;
    } else {
      startTime = endTime - 3 * 30 * 24 * 60 * 60 * 1000;
    }
  }
  return [startTime, endTime];
}
