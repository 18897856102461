import { BaseCamera } from './camera';
import { LocationFilterValue } from './common';
import { PartialLocation } from './location';

export interface AudioDevice {
  clientAudioDeviceId: string;
  id: number;
  location: PartialLocation;
  deviceType: AudioDeviceType;
  url: string;
  userName?: string;
  passwordFilePath?: string;
  tenantId?: string;
  enableAutomatedTalkdown: boolean;
  cameras: BaseCamera[];
}
type AudioDeviceType = typeof audioDeviceTypes[number];
export const audioDeviceTypes = ['AXIS', 'ACS', 'ONVIF', 'AXIS_SIREN'];
export interface AudioDevicesDTO {
  items: AudioDevice[];
  total: number;
}

export type AddAudioDevicePayload = Partial<AudioDevice>[];
export interface ModifyAudioDeviceCamMappingPayload {
  audioDeviceId: number;
  cameraId: string;
}

export interface AudioFilterValues {
  location: LocationFilterValue;
}
