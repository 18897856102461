import { useScanWs } from '@hakimo-ui/hakimo/util';
import { useEffect } from 'react';

export function useScanWsMessageHandler(
  messageType: string,
  handler: (data: any) => void
) {
  const { subscribe } = useScanWs();

  useEffect(() => {
    // Subscribe to messages with type 'update' (or any other topic)
    const unsubscribe = subscribe?.(messageType, handler);
    return () => {
      unsubscribe?.();
    };
  }, [handler, messageType, subscribe]);
}
