import { useLocationContacts } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import ContactConnect from './contact-connect/ContactConnect';

interface Props {
  locationId: number;
  locationAlarmId?: string;
  locationTenantId?: string;
}

export function LocationContacts(props: Props) {
  const { locationId, locationAlarmId, locationTenantId } = props;
  const queryResult = useLocationContacts(locationId);

  return (
    <QueryResult queryResult={queryResult}>
      {(contacts) => {
        const sortedContacts = contacts.sort((a, b) => b.priority - a.priority);
        return (
          <>
            <h2 className="text-primary-800 dark:text-primary-400 flex h-9 items-center font-sans font-semibold">
              Location Contacts
            </h2>
            <div className="dark:divide-ondark-line-2 divide-onlight-line-2 divide-y p-2">
              {sortedContacts.map((contact) => (
                <ContactConnect
                  toNumber={contact.phoneNumber}
                  toName={contact.name}
                  key={contact.id}
                  locationAlarmId={locationAlarmId}
                  locationTenantId={locationTenantId}
                  tag={contact.tag}
                />
              ))}
            </div>
          </>
        );
      }}
    </QueryResult>
  );
}

export default LocationContacts;
