import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { LocationContact } from '@hakimo-ui/hakimo/types';
import { DragIcon } from '@hakimo-ui/hakimo/ui-elements';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

interface SortableItemProps {
  contact: LocationContact;
  onEdit: () => void;
  onDelete: () => void;
}

export function SortableItem(props: SortableItemProps) {
  const { contact, onEdit, onDelete } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: contact.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? 'grabbing' : 'grab',
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="hover:bg-onlight-bg-2 dark:hover:bg-ondark-bg-2 border-onlight-line-2 dark:border-ondark-line-2 grid grid-cols-6 border px-4 py-6"
    >
      <div className="flex items-center gap-4">
        <DragIcon className="fill-onlight-text-2 dark:fill-ondark-text-2 ml-2 h-4 w-4" />
        <span>{contact.priority}</span>
      </div>
      <div>{contact.name}</div>
      <div>{contact.phoneNumber}</div>
      <div>{contact.email || '-'}</div>
      <div>{contact.tag || '-'}</div>
      <div className="space-x-4">
        <Button variant="icon" onClick={onEdit} title="Edit contact details">
          <PencilIcon className="h-5 w-5" />
        </Button>
        <Button variant="icon" onClick={onDelete} title="Delete contact">
          <TrashIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
}

export default SortableItem;
