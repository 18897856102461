import { MotionDetectionWindow } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateMotionDetectionWindow(
  locationId: number,
  onSuccess: () => void
) {
  const url = `/v2/orm/locations/${locationId}/monitoring/window`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, MotionDetectionWindow>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['location_monitoring_window']],
  });
}

export default useUpdateMotionDetectionWindow;
