import { useHttpClient } from '@hakimo-ui/hakimo/util';
import { useQuery } from 'react-query';

interface AIOutputLocationAlarmResponse {
  recommendations: string;
  summary: string;
}

interface APIResponse {
  message: string;
  payload: AIOutputLocationAlarmResponse;
  status: number;
}

export function useAIOutputLocationAlarm(alarmId: string) {
  const httpClient = useHttpClient();
  const url = `/v2/orm/ai_outputs_location_alarm/?alarm_id=${alarmId}`;

  return useQuery<AIOutputLocationAlarmResponse>(
    ['ai_outputs_location_alarm', alarmId],
    async () => {
      const response = await httpClient<APIResponse>(url);
      return response.payload;
    },
    {
      enabled: !!alarmId,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

export default useAIOutputLocationAlarm;
