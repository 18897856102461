import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPManager } from '@hakimo-ui/hakimo/feature-shared';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';

export interface Props {
  locationId: string;
}

export function SOP(props: Props) {
  const { locationId } = props;
  const queryResult = useSOP({
    locationId,
  });

  return (
    <div>
      <h2 className="flex h-9 items-center">Standard Operating Procedure</h2>
      <QueryResult queryResult={queryResult} loadingText="">
        {({ sop_workflow: sopWorkflow, id }) => (
          <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 overflow-hidden">
            <SOPManager
              showTitle={false}
              sopId={id ?? ''}
              sopWorkflow={sopWorkflow}
            />
          </div>
        )}
      </QueryResult>
    </div>
  );
}

export default SOP;
